import { createSlice } from "@reduxjs/toolkit";

const massTagSlice = createSlice({
    name: "massTag",
    initialState: {
        searchContext: {
            quickFilter: {
                dataSourceIds: [],
                dataTypes: [],
                tags: [],
                entities: [],
                participants: [],
                filterSearchQuery: "",
                intelligentSearchQuery: "",
                startDate: "",
                endDate: "",
                timeZone: "",
                includeBlankDate: false,
                selectAllUntagged: false,
            },
            savedSearchId: null,
            isShowRecents: false,
        }
    },
    reducers: {
        setSearchContext: (state, action) => {
            state.searchContext = action.payload;
        },
        setSearchContextQuickFilter: (state, action) => {
            state.searchContext.quickFilter = action.payload;
        },
        resetSearchContext: (state) => {
            state.searchContext = {
                quickFilter: {
                    dataSourceIds: [],
                    dataTypes: [],
                    tags: [],
                    entities: [],
                    participants: [],
                    filterSearchQuery: "",
                    intelligentSearchQuery: "",            
                    startDate: "",
                    endDate: "",
                    includeBlankDate: false,
                    selectAllUntagged: false,
                },
                savedSearchId: null,
                isShowRecents: false,
            };
        },
        setMassTagSelectAllUntagged: (state, action) => {
            state.searchContext.quickFilter.selectAllUntagged = action.payload;
        }
    },
});

export const { 
    setSearchContext,
    setSearchContextQuickFilter,
    resetSearchContext,
    setMassTagSelectAllUntagged
} = massTagSlice.actions;
export const MassTagReducer= massTagSlice.reducer;