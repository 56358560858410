export const LIMIT_FILE = {
  recognition: "image/png, image/jpg, image/jpeg",
};

export const IMPORT_STATUS = {
  notReady: 1,
  inProgress: 2,
  failed: 3,
  completed: 4,
  cancelling: 5,
  ready: 6,
  uploading: 7,
  pending: 8,
  deleting: 9,
  deletedFailed: 10,
  deleted: 11,
};
