import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { setCanExecuteHotKey } from "store/TagReducer";

// Components
import ReactSelect from 'react-select';

// Constants
import { REGEX_ONLY_NUMERIC, REGEX_ONLY_LABEL } from "constants/RegexConstant";
import { DROPDOWN_LABEL, KEY_CODE } from "constants/Common";

// Styles
import "./styles.scss";

export const PaginationResult = (props) => {
  const {
    perPage,
    handlePagingClick,
    totalRecord,
    pageItems,
    forcePage = 0,
    isSmall,
    isUpdateCurrentPage = false,
    nameRecord = "",
    selectedLabel = "",
    options = {}
  } = props;

  const { labels = [] } = options;
  const multipleLabels = labels.length > 1;

  const labelOptions = labels.map(({ label }) => ({ value: label, label }));

  const dispatch = useDispatch();
  const [pageCountContact, setPageCountContact] = useState(perPage);

  const [currentInput, setCurrentInput] = useState(
    labels[0]?.defaultValue ?? (forcePage + 1)
  );

  const [currentLabel, setCurrentLabel] = useState({
    value: multipleLabels ? labels[0].label : DROPDOWN_LABEL.page,
    label: multipleLabels ? labels[0].label : DROPDOWN_LABEL.page,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setCanExecuteHotKey(true));
    if (currentLabel.value === DROPDOWN_LABEL.page && currentInput && Number(currentInput) <= pageCountContact && Number(currentInput) > 0)
      handlePagingClick({ label: currentLabel.value, selected: Number(currentInput - 1) });
    else
      handlePagingClick({ label: currentLabel.value, selected: currentInput });
  };

  const onChangeInput = (e) => {
    const { value } = e.target;
  
    if (currentLabel.value === DROPDOWN_LABEL.page) {
      if (REGEX_ONLY_NUMERIC.test(value)) 
        setCurrentInput(value);
    } else {
      if (REGEX_ONLY_LABEL.test(value))
        setCurrentInput(value); 
    }
  };
  
  const onKeyDown = (e) => {
    if (e.keyCode === KEY_CODE.enter)
      handleSubmit();
  };

  const onSwitchLabel = (selectedOption) => {
    const defaultValue = labels.find(({ label }) => label === selectedOption.value)?.defaultValue;
    setCurrentInput(defaultValue || forcePage + 1);
    setCurrentLabel(selectedOption);
  };

  useEffect(() => {
    setPageCountContact(Math.ceil(totalRecord / perPage));
  }, [totalRecord]);

  useEffect(() => {
    setCurrentInput(forcePage + 1);
  }, [forcePage, isUpdateCurrentPage]);

  useEffect(() => {
    setCurrentInput(labels[0]?.defaultValue ?? (forcePage + 1));
  }, [labels[0]?.defaultValue]);

  return (
    <div className="paging_control">
      <p className="paging_control-showing">
        Showing {pageItems} of {totalRecord.toLocaleString()}{" "}
        {nameRecord || `item${totalRecord > 1 ? "s" : ""}`}
        {selectedLabel && ` (${selectedLabel})`}
      </p>
      <div className="paging_control-pagination">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCountContact}
          onPageChange={handlePagingClick}
          containerClassName={`pagination ${isSmall && "pagination-small"}`}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          forcePage={forcePage}
        />
        <div className="search-paging">
          {multipleLabels ? (
            <div className="react-select-custom">
              <ReactSelect
                classNamePrefix="react-select"
                options={labelOptions}
                value={currentLabel}
                onChange={onSwitchLabel}
                isDisabled={false}
              />
            </div>
          ) : (
            <span>
              {labels.length === 1 ? labels[0].label : DROPDOWN_LABEL.page }
            </span>
          )}
          <form onSubmit={handleSubmit}>
            <input
              className={multipleLabels ? 'input-wide' : ''}
              onChange={onChangeInput}
              onKeyDown={onKeyDown}
              value={currentInput}
              maxLength={30}
              onFocus={() => dispatch(setCanExecuteHotKey(false))}
              onBlur={() => dispatch(setCanExecuteHotKey(true))}
            />
          </form>
          <img
            src="/images/search-icon.svg"
            alt="Search Icon"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

PaginationResult.propTypes = {
  perPage: PropTypes.number,
  totalRecord: PropTypes.number,
  handlePagingClick: PropTypes.func,
  pageItems: PropTypes.number,
  forcePage: PropTypes.number,
  isSmall: PropTypes.bool,
  isUpdateCurrentPage: PropTypes.bool,
  nameRecord: PropTypes.string,
  selectedLabel: PropTypes.string,
  options: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string)
  })
};
