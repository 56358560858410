import axios, { authHeader, getAbortController } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

// Api get custodian
const getDataSourceApi = (projectId, bodyData) =>
  axios(`/custodian/${projectId}/dataSource`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
  });

const exportDataSourceToExcel = (projectId, bodyData) =>
  axios(`/custodian/${projectId}/exportExcel`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
    responseType: "blob",
  });

const createSourceApi = (projectId, bodyData) =>
  axios(`/custodian/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
    signal: getAbortController().signal,
  });

const deleteSourceApi = (projectId, dataSourceID) =>
  axios(`/custodian/${projectId}/dataSource?dataSourceId=${dataSourceID}`, {
    headers: authHeader(),
    method: METHOD_API.delete,
  });

const updateSourceApi = (projectId, bodyData) => {
 return axios(`/custodian/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.put,
    data: bodyData,
  });
}

// Api get Data source list
const getDataSourceListApi = (projectId) =>
  axios.get(`/custodian/${projectId}/dataSource`, {
    headers: authHeader(),
    signal: getAbortController().signal,
  });

export {
  getDataSourceApi,
  exportDataSourceToExcel,
  createSourceApi,
  deleteSourceApi,
  getDataSourceListApi,
  updateSourceApi
};
