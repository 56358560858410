import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";
import { isEmpty } from "lodash";
import useKeyDownListener from "hook/KeyDownListener";

// Services
import { postSelectTagsGeneral } from "services/GeneralReviewService";
import { executeTagEmailApi } from "services/EmailReviewService";

// Components
import PreviewNotAvailable from "./preview-not-available/PreviewNotAvailable";
import PreviewAudio from "./preview-audio/PreviewAudio";
import PreviewHistoryLink from "./preview-history-link/PreviewHistoryLink";
import PreviewImage from "./preview-image/PreviewImage";
import PreviewLocation from "./preview-location/PreviewLocation";
import PreviewVideo from "./preview-video/PreviewVideo";
import PreviewNote from "./preview-note/PreviewNote";
import PreviewChat from "./preview-chat/PreviewChat";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import EmailPreviewSelectTag from "components/review/email/email-preview/EmailPreviewSelectTag";
import TagControl from "components/tag-management/tag-control/TagControl";
import { Button } from "components/shared/button/Button";
import { RenderTagSelected } from "components/shared/render-tag-selected/RenderTagSelected";
import PreviewFile from "./preview-file/PreviewFile";

// Store
import { setCanExecuteHotKey, setIsExecutingTag } from "store/TagReducer";
import { setIsGoToOrigin } from "store/CommonReducer";
import { resetItemDetailData } from "store/EventTimelineReducer";

// Helpers
import { showToast } from "helpers/ToastHelper";
import {
  getTitleHeaderFromType,
  checkPreviewHasData,
} from "helpers/ItemDetailHelper";
import { handleUpdateTag } from "helpers/CommonHelper";
import { getFileNameFromUrl, getFileNameFromUrl as getScreenNameFromPathname } from "helpers/GetFileNameHelper";
import { handlePressHotKey } from "helpers/HotKeyHelper";

// Constants
import { CHAT_EMAIL_TYPE, DATA_TYPE } from "constants/DataType";
import {
  TAG_ACTION,
  TAG_RESPONSE_MESSAGE,
  TYPE_WITH_TAG_ACTION,
} from "constants/Constants";
import {
  COLORS,
  DISPLAY_TYPE,
  EXPORT_TAG,
  PAGE_NAME,
  PATH_NAME,
} from "constants/Common";
import { RESPONSE_STATUS } from "constants/StatusCodeConstant";

// Styles
import styles from "./PreviewResultContainer.module.scss";

const PreviewResultContainer = ({
  setIsResultLoading = () => {},
  loading = false,
  data = {},
  participants = [],
  tagsList = [],
  showTagsList = [],
  reviewType = "",
  searchMsgInput = "",
  paging = {},
  dateTime = {},
  totalItemOfPage = 0,
  totalSearchCount = 0,
  setTotalSearchCount = () => {},
  totalMessageContainKeyWord = 0,
  setTotalMessageContainKeyWord = () => {},
  listPageNumber = [],
  setListPageNumber = () => {},
  setSearchMsgInputStore = () => {},
  onSetParamIsNewestStore = () => {},
  setInstantMessagesStore = () => {},
  isSwitchChat = false,
  displayType = "",
  setIsShowPreviewModal = () => {},
  handleSearchEmailDetailByDate = () => {},
  fetchChatDetailApi = () => {},
  showTagComponent = true,
  allFirstLoadMessages = [],
  titleActivity = "",
  isFirstLoad = true,
  setIsFirstLoad = () => {},
  currentID = "",
  isImgNotFound = false,
  isShowOrigin = true,
  isShowCheckBox = true,
  onClearSearchMsg,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // useHook
  // defined 2 states at here, because if define it on Preview chat component, an error un-limit loops will be happened
  const [index, setIndex] = useState(0);
  const [listIndex, setListIndex] = useState([]);
  const { projectId, searchResultId } = useParams();
  const [canShowTagAction, setCanShowTagAction] = useState(false);

  // Tag
  const [isResetTag, setIsResetTag] = useState(false);
  const [isAddTag, setIsAddTag] = useState(false);
  const [currentTags, setCurrentTags] = useState([]); // use for change tags to show without reload or get api again, only id
  const [emailSelected, setEmailSelected] = useState([]);
  const [isShowTag, setIsShowTag] = useState(true);
  const [canUntag, setCanUntag] = useState(false);
  const [emailPreviewList, setEmailPreviewList] = useState([]);

  // Store
  const { canExecuteHotKey, isExecutingTag } = useSelector(
    (state) => state.tag
  );

  // Fill and reset color when search email
  useEffect(() => {
    const ele = document.getElementById(`element-pos-${index}`);
    if (ele) {
      ele.style.backgroundColor = COLORS.blue;
      const eleBefore = document.getElementById(`element-pos-${index - 1}`);
      const eleAfter = document.getElementById(`element-pos-${index + 1}`);
      if (eleBefore && index > 0)
        eleBefore.style.backgroundColor = COLORS.yellow;
      if (eleAfter) eleAfter.style.backgroundColor = COLORS.yellow;
    }
  }, [index]);


  const renderResultPreview = (type) => {
    switch (type) {
      case DATA_TYPE.voicemail:
        return <PreviewAudio matterId={projectId} attachmentId={data?.id} filename={getFileNameFromUrl(data?.url)} />;
      case DATA_TYPE.image:
        return (
          <PreviewImage
            data={data}
            displayType={displayType}
            isDetailPage={pathname.includes(PATH_NAME.detail)}
            isShowAttachment={true}
          />
        );
      case DATA_TYPE.video:
        return <PreviewVideo attachmentId={data?.id} matterId={projectId} />;
      case DATA_TYPE.note:
        return <PreviewNote matterId={projectId} data={data} />;
      case DATA_TYPE.historyLink:
        return <PreviewHistoryLink url={data?.url} />;
      case DATA_TYPE.location: {
        const { address, latitude, longitude } = data;
        const dataLocation = {
          position: {
            latitude,
            longitude,
          },
          address,
        };
        return <PreviewLocation dataAttach={dataLocation} />;
      }
      case DATA_TYPE.audio:
        return <PreviewAudio matterId={projectId} attachmentId={data?.id} filename={data?.title} />;
      case DATA_TYPE.file:
        return <PreviewFile projectId={projectId} attachmentId={data?.id} contentType={data?.contentType} recordType={data?.recordType} hasHtml={data?.hasHtml} />;
      case DATA_TYPE.contact:
      case DATA_TYPE.callLog:
      case DATA_TYPE.network:
      case DATA_TYPE.creditCard:
      case DATA_TYPE.database:
        return <PreviewNotAvailable />;
      case DATA_TYPE.chatGroup:
      case DATA_TYPE.chat:
        return (
          <PreviewChat
            displayType={displayType}
            data={data}
            participants={participants}
            loading={loading}
            paging={paging}
            dateTime={dateTime}
            searchMsgInput={searchMsgInput}
            totalItemOfPage={totalItemOfPage}
            index={index}
            setIndex={setIndex}
            listIndex={listIndex}
            setListIndex={setListIndex}
            totalSearchCount={totalSearchCount}
            setTotalSearchCount={setTotalSearchCount}
            totalMessageContainKeyWord={totalMessageContainKeyWord}
            setTotalMessageContainKeyWord={setTotalMessageContainKeyWord}
            listPageNumber={listPageNumber}
            setListPageNumber={setListPageNumber}
            setSearchMsgInputStore={setSearchMsgInputStore}
            onSetParamIsNewestStore={onSetParamIsNewestStore}
            isSwitchChat={isSwitchChat}
            setInstantMessagesStore={setInstantMessagesStore}
            setIsShowPreviewModal={setIsShowPreviewModal}
            fetchChatDetailApi={fetchChatDetailApi}
            showTagComponent={showTagComponent}
            allFirstLoadMessages={allFirstLoadMessages}
            titleActivity={titleActivity}
            isFirstLoad={isFirstLoad}
            setIsFirstLoad={setIsFirstLoad}
            currentID={currentID}
            reviewType={reviewType}
            isShowCheckBox={isShowCheckBox}
          />
        );
      case DATA_TYPE.emailThread:
      case DATA_TYPE.email:
        return (
          <EmailPreviewSelectTag
            displayType={displayType}
            setLoading={setIsResultLoading}
            canUntag={canUntag}
            emailList={emailPreviewList}
            emailSelected={emailSelected}
            resetSelected={resetSelected}
            loading={loading}
            emailResultTotal={emailPreviewList?.length}
            handleChecked={handleChecked}
            onSaveTag={handleSaveTagForEmail}
            onSearchByDate={handleSearchEmailDetailByDate}
            handleIsAddTag={(value) => setIsAddTag(value)}
            isShowTag={!isShowCheckBox ? false : isShowTag}
            showTagComponent={showTagComponent}
            isHideBorderWrap={true}
            title="Preview"
            paging={paging}
            searchEmailInput={searchMsgInput}
            onClearSearchMsg={onClearSearchMsg}
            setSearchMsgInputStore={setSearchMsgInputStore}
            setIndex={setIndex}
            index={index}
            setTotalSearchCount={setTotalSearchCount}
            totalSearchCount={totalSearchCount}
          />
        );
      default:
        break;
    }
  };

  /*
    Receive 2 params: 
      - Type: tag or un-tag
      - Tag: can pass list tag want to add or give an empty value
  */
  const onTagging = async (tags, type) => {
    if (isEmpty(data?.id)) return;
    if (!CHAT_EMAIL_TYPE.includes(reviewType)) {
      dispatch(setIsExecutingTag(true));
      try {
        let params = {
          ids: [data?.id],
          tags,
          isRemove: type === TAG_ACTION.unTag,
          titleActivity,
        };
        const result = await postSelectTagsGeneral(
          projectId,
          reviewType,
          params
        );
        if (result.status === RESPONSE_STATUS.success) {
          setCurrentTags((prev) => handleUpdateTag(prev, tags, type));
          // Hide tag dropdown
          setIsResetTag(!isResetTag);
        }
      } catch (e) {
        showToast(
          "error",
          toast,
          type === TAG_ACTION.unTag
            ? TAG_RESPONSE_MESSAGE.unTagFailed
            : TAG_RESPONSE_MESSAGE.tagFailed,
          "toast-01"
        );
        console.log(e);
      } finally {
        dispatch(setIsExecutingTag(false));
      }
    }
  };

  // Observe keydown by user
  const keydownListener = (event) => {
    const condition =
      !TYPE_WITH_TAG_ACTION.includes(reviewType) ||
      ([DATA_TYPE.emailThread, DATA_TYPE.email].includes(reviewType) &&
        !emailSelected?.length) ||
      !canExecuteHotKey ||
      isAddTag ||
      !canShowTagAction ||
      isExecutingTag;
    if (condition || !event) return;
    handlePressHotKey(event, tagsList, handleKeyPressSelectTag);
  };

  // Handle when press hotkey
  const handleKeyPressSelectTag = async (e, key, tag) => {
    if (e.keyCode === EXPORT_TAG.keyCode) return;
    if (e.keyCode === key) {
      e.preventDefault();
      if ([DATA_TYPE.emailThread, DATA_TYPE.email].includes(reviewType))
        handleSaveTagForEmail([tag]?.flat(1), TAG_ACTION.tag);
      else onTagging(tag, TAG_ACTION.tag);
    }
  };

  // handle checked email
  const handleChecked = (event) => {
    const { checked, value } = event.currentTarget;
    setEmailSelected((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  const resetSelected = () => setEmailSelected([]);

  //Update email with new tag
  const updateEmailAfterTag = (
    sourceEmail,
    checkedEmail,
    tagToExecutes,
    type
  ) => {
    const emailTemp = sourceEmail.map((email) => {
      if (checkedEmail.includes(email.emailID))
        return {
          ...email,
          tags: handleUpdateTag(email.tags, tagToExecutes, type),
        };
      return email;
    });
    return emailTemp;
  };

  //execute Tag for email
  const executeTagForEmail = async (tagToExecutes, type) => {
    if (isEmpty(data?.id || data?.threadID)) return;
    dispatch(setIsExecutingTag(true));
    try {
      const params = {
        threadId: data.threadID ?? data.id,
        tags: tagToExecutes,
        ids: emailSelected,
        isRemove: type === TAG_ACTION.unTag,
        titleActivity,
      };
      const postSelectTags = await executeTagEmailApi(projectId, params);
      if (postSelectTags.status === RESPONSE_STATUS.success) {
        const newEmails = updateEmailAfterTag(
          emailPreviewList,
          emailSelected,
          tagToExecutes,
          type
        );
        //updateEmailToShow
        setEmailPreviewList(newEmails);
        // Prevent scroll to index when message changes
        setIsShowTag(true);
        setEmailSelected([]);
      }
    } catch (e) {
      showToast(
        "error",
        toast,
        type === TAG_ACTION.unTag
          ? TAG_RESPONSE_MESSAGE.unTagFailed
          : TAG_RESPONSE_MESSAGE.tagFailed,
        "toast-01"
      );
      console.log(e);
    } finally {
      dispatch(setIsExecutingTag(false));
    }
  };

  //handle save tag for email
  const handleSaveTagForEmail = (selectedTag, type) => {
    if (selectedTag && emailSelected) {
      executeTagForEmail(selectedTag, type);
    }
  };

  const gotoOriginOfPreview = () => {
    if (!data) return;
    const title = getTitleHeaderFromType(data.parentType);
    // reset store event timeline detail
    dispatch(resetItemDetailData());
    dispatch(setIsGoToOrigin(true));
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.itemDetail}`, {
      state: {
        id: data.parentID,
        attachmentId: data.id,
        eventTimeLineType: data.parentType,
        headerTitle: title,
        pathname,
        pageName: PAGE_NAME.viewDetail,
        titleActivity,
        searchResultId: searchResultId || "",
        timelineDetailTitle: getTitleHeaderFromType(data.type),
      },
    });
  };

  const showGotoOrigin = () => {
    if (!data) return false;
    const typesAllowed = [
      DATA_TYPE.image,
      DATA_TYPE.video,
      DATA_TYPE.audio,
      DATA_TYPE.file,
    ];
    const splitPaths = getScreenNameFromPathname(pathname);
    return (
      typesAllowed.includes(data.type) &&
      splitPaths !== "intelligent-search" &&
      data.parentID
    );
  };

  useKeyDownListener(keydownListener, [keydownListener]);

  //Email Handler
  useEffect(() => {
    if ([DATA_TYPE.emailThread, DATA_TYPE.email].includes(reviewType))
      setEmailPreviewList(data?.emails);
  }, [data]);

  useEffect(() => {
    setEmailSelected([]);
  }, [data?.id]);

  useEffect(() => {
    if ([DATA_TYPE.email, DATA_TYPE.emailThread].includes(reviewType)) {
      if (emailSelected.length > 0) dispatch(setCanExecuteHotKey(true));
      else dispatch(setCanExecuteHotKey(false));
    }
    setCanUntag(
      emailPreviewList?.some(
        (email) =>
          emailSelected.includes(email.emailID) &&
          email.tags?.length > 0 &&
          showTagsList.some((tag) => email.tags.includes(tag.tagID))
      )
    );
  }, [emailSelected]);

  // Set current tag when data has changed
  useEffect(() => {
    if (data) setCurrentTags(data.tags);
    // Check show tag, un-tag button
    const check =
      checkPreviewHasData(data)?.trim() !== "" ||
      checkPreviewHasData(data) !== null;
    setCanShowTagAction(check);
    dispatch(setCanExecuteHotKey(true));
  }, [data?.id]);

  return (
    <div
      className={clsx(
        styles["is-result-preview-wrap"],
        displayType === DISPLAY_TYPE.preview ? styles["preview-wrap"] : ""
      )}
    >
      {isExecutingTag && (
        <div className={styles["executing-tag"]}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!CHAT_EMAIL_TYPE.includes(reviewType) || paging?.totalRecords === 0 ? (
        <>
          <div className={styles["is-result-preview-head"]}>
            <div className={styles["is-result-preview-select"]}>
              <h5 className={styles["is-result-preview-title"]}>Viewer</h5>
              <div className={clsx(styles["render-tag-container"])}>
                {!loading &&
                  paging?.totalRecords !== 0 &&
                  !CHAT_EMAIL_TYPE.includes(reviewType) && (
                    <RenderTagSelected
                      isHorizontal
                      tagsSelected={currentTags}
                    />
                  )}
              </div>
              {reviewType && totalItemOfPage > 0 && (
                <div className={clsx(styles["select-tag"])}>
                  <TagControl
                    isLoadingData={loading}
                    disableTag={paging?.totalRecords === 0}
                    disableTagButton={data === null || paging?.totalRecords === 0}
                    disableRemoveButton={false}
                    handleSaveSelectTags={onTagging}
                    handleIsAddTag={(value) => setIsAddTag(value)}
                  />
                </div>
              )}
              {showGotoOrigin() && isShowOrigin && (
                  <div className={styles["origin-button"]}>
                    <Button
                      name="Go to Origin"
                      altIcon="Go to origin"
                      handleClick={gotoOriginOfPreview}
                      className="btn-small"
                      isDisabled={loading}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className={clsx(
            styles["message-detail"],
            reviewType === DATA_TYPE.file && styles["no-border"]
          )}>
            <div className={clsx(
              styles["message-body"],
              reviewType === DATA_TYPE.file && styles["no-padding"]
            )}>
              {loading ? (
                <div className={styles["loading-preview"]}>
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  {data === null || paging?.totalRecords === 0 ? (
                    <EmptyPage
                      size={displayType === DISPLAY_TYPE.preview ? "md" : ""}
                      messages="No results found. Please try again."
                      isImgnotFound={isImgNotFound}
                    />
                  ) : (
                    renderResultPreview(reviewType)
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        renderResultPreview(reviewType)
      )}
    </div>
  );
};

PreviewResultContainer.propTypes = {
  reviewType: PropTypes.string,
  searchMsgInput: PropTypes.string,
  titleActivity: PropTypes.string,
  displayType: PropTypes.string,
  currentID: PropTypes.string,
  totalItemOfPage: PropTypes.number,
  totalSearchCount: PropTypes.number,
  totalMessageContainKeyWord: PropTypes.number,
  loading: PropTypes.bool,
  isSwitchChat: PropTypes.bool,
  showTagComponent: PropTypes.bool,
  isFirstLoad: PropTypes.bool,
  isImgNotFound: PropTypes.bool,
  isShowOrigin: PropTypes.bool,
  isShowCheckBox: PropTypes.bool,
  data: PropTypes.object,
  paging: PropTypes.object,
  dateTime: PropTypes.object,
  tagsList: PropTypes.array,
  participants: PropTypes.array,
  showTagsList: PropTypes.array,
  listPageNumber: PropTypes.array,
  allFirstLoadMessages: PropTypes.array,
  setTotalSearchCount: PropTypes.func,
  setTotalMessageContainKeyWord: PropTypes.func,
  setListPageNumber: PropTypes.func,
  setSearchMsgInputStore: PropTypes.func,
  onSetParamIsNewestStore: PropTypes.func,
  setInstantMessagesStore: PropTypes.func,
  setIsShowPreviewModal: PropTypes.func,
  handleSearchEmailDetailByDate: PropTypes.func,
  setIsResultLoading: PropTypes.func,
  fetchChatDetailApi: PropTypes.func,
  setIsFirstLoad: PropTypes.func,
  onClearSearchMsg: PropTypes.func,
};

export default PreviewResultContainer;
