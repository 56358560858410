import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import FadeLoader from "react-spinners/FadeLoader";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { DATE_TIME_TYPE } from "constants/Constants";

// Components
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import EmptyPage from "components/shared/empty-page/EmptyPage";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./ViewLog.module.scss";

const ViewLog = (props) => {
  const {
    show = true,
    onClose,
    logs = [],
    loading = false,
    isImporting = false,
    dataSourceId,
    deviceName,
  } = props;

  const handleDownloadLogs = () => {
    const logContent = logs.map(log => log.content).join('\n');
    const blob = new Blob([logContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    const timestamp = formatDateTime({
      dateTime: new Date(),
      type: DATE_TIME_TYPE.ISO
    });
    const sanitizedDeviceName = deviceName ?
      deviceName.replace(/[^a-z0-9]/gi, '_').toLowerCase() :
      `datasource_${dataSourceId}`;
    const filename = `import_logs_${sanitizedDeviceName}_${timestamp}.txt`;

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (loading) return;
    const element = document.getElementById(`log-${logs.length - 1}`);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
  }, [logs, loading]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      className={styles["modal-container"]}
      dialogClassName={styles["modal-dialog"]}
      contentClassName={styles["modal-content"]}
    >
      <Modal.Header closeButton className={styles["modal-header"]}>
        <div className={styles["header-content"]}>
          <span className={styles["title"]}>Recent Logs</span>
          {logs.length > 0 && !loading && (
            <button
              type='button'
              onClick={handleDownloadLogs}
              className={styles["download-btn-icon"]}
            >
              <img
                src='/images/icons/download.svg'
                alt='Download'
              />
            </button>
          )}
        </div>
      </Modal.Header>
      <Modal.Body className={styles["modal-body"]}>
        {loading ? (
          <SpinnerLoading />
        ) : logs.length > 0 ? (
          logs.map((item, index) => (
            <p
              className={item.isFailed ? styles["err"] : ""}
              key={index}
              id={`log-${index}`}
            >
              {item.content}
            </p>
          ))
        ) : (
          <EmptyPage size="md" messages="No logs yet. Please import data." />
        )}
      </Modal.Body>
      <Modal.Footer className={styles["modal-footer"]}>
        {isImporting && !loading && logs.length > 0 && (
          <div className={styles["loading"]}>
            <FadeLoader
              className={styles["fade-loader"]}
              color={COLORS.blue}
              height={5}
              width={2}
              margin={-10}
            />
            <span>Running...</span>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ViewLog.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  isImporting: PropTypes.bool,
  onClose: PropTypes.func,
  logs: PropTypes.arrayOf(
    PropTypes.shape({ content: PropTypes.string, isFailed: PropTypes.bool })
  ),
  dataSourceId: PropTypes.number,
  deviceName: PropTypes.string,
};

export default ViewLog;
