import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { BeatLoader } from "react-spinners";

// Components
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import { Button } from "components/shared/button/Button";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./ConfigIndex.module.scss";
import Checkbox from "components/shared/checkbox/Checkbox";

const ConfigIndex = (props) => {
  const {
    titleConfig = "",
    projectId = 0,
    isLoading = false,
    isLoadingProject = false,
    options = [],
    onChangeProjectId = () => { },
    handleRunConfig = () => { },
  } = props;

  const [indexOptions, setIndexOptions] = React.useState([{
    extractAttachments: false, identifyLanguage: false
  }]);

  const onChangeExtractAttachments = () => {
    setIndexOptions({ extractAttachments: !indexOptions?.extractAttachments });
  }

  const onChangeIdentifyLanguage = () => {
    setIndexOptions({ identifyLanguage: !indexOptions?.identifyLanguage });
  }

  return (
    <div className={styles["wrap"]}>
      <h3>{titleConfig}</h3>
      <div className={clsx(styles["form-group"], "app-form-group")}>
        <label className="app-form-label">
          Project Name
          <span className={styles["form-required"]}> *</span>
        </label>
        <DropdownGeneral
          className={clsx(
            "dropdown-react-select",
            "w-100",
            styles["select-project"]
          )}
          placeholder="Select Project Name"
          options={options}
          isLoading={isLoadingProject}
          isDisabled={isLoadingProject}
          onChange={onChangeProjectId}
          value={options.filter((item) => item.value === projectId)}
        />
      </div>
      <div className={styles["attachment-checkbox"]}>
        <Checkbox
          id="extractattachments"
          label="Extract Attachments"
          handleClick={onChangeExtractAttachments}
          isChecked={indexOptions.extractAttachments}
          size="lg"
        />
      </div>
      <div className={styles["language-checkbox"]}>
        <Checkbox
          id="identifylanguage"
          label="Identify Language"
          handleClick={onChangeIdentifyLanguage}
          isChecked={indexOptions.identifyLanguage}
          size="lg"
        />
      </div>
      {isLoading ? (
        <BeatLoader
          color={COLORS.black}
          loading={true}
          size={8}
          className={styles["beat-loader"]}
        />
      ) : (
        <Button
          name={titleConfig}
          className="btn-primary-fill"
          handleClick={() => handleRunConfig(indexOptions)}
        />
      )}
    </div>
  );
};

ConfigIndex.propTypes = {
  titleConfig: PropTypes.string,
  projectId: PropTypes.number,
  options: PropTypes.array,
  isShowProjectId: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingProject: PropTypes.bool,
  onChangeProjectId: PropTypes.func,
  handleRunConfig: PropTypes.func,
};

export default ConfigIndex;
