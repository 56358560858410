import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHeatMapApi } from "services";

export const fetchHeatMap = createAsyncThunk(
  "heatMap/fetchHeatMap",
  async (request) => {
    return await getHeatMapApi(request.projectId, request.data);
  }
);

export const paramSearchInitHeatMap = {
  Types: [],
  DataSources: [],
  Search: null,
  IsValidSearch: true,
  Entities: [],
  Participants: [],
  typeFilter: 0,
  start: null,
  end: null,
  timeZone: null,
  includeBlankDate: false,
};

const initialState = {
  quickFilter: {
    entities: [],
    participants: [],
    dataTypes: [],
    dataSources: [],
  },
  heatMapData: {
    data: [],
    listName: [],
  },
  paramSearch: paramSearchInitHeatMap,
  loading: true,
};

const heatMapSlice = createSlice({
  name: "heatMap",
  initialState,
  reducers: {
    setHeatMapQuickSelectData: (state, action) => {
      state.quickFilter = action.payload;
    },
    setHeatMapData: (state, action) => {
      state.heatMapData = action.payload;
    },
    setParamSearchHeatMap: (state, action) => {
      state.paramSearch = { ...state.paramSearch, ...action.payload };
    },
    clearHeatmapData: () => initialState,
    setLoadingHeatMap: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [fetchHeatMap.pending]: (state) => {
      state.loading = true;
    },
    [fetchHeatMap.rejected]: (state) => {
      state.loading = false;
    },
    [fetchHeatMap.fulfilled]: (state, action) => {
      state.loading = false;
      state.heatMapData = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setHeatMapQuickSelectData,
  setHeatMapData,
  setParamSearchHeatMap,
  clearHeatmapData,
  setLoadingHeatMap,
} = heatMapSlice.actions;
export const HeatMapReducer = heatMapSlice.reducer;
