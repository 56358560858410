import React from "react";
import PropTypes from "prop-types";

// Components
import { Modal } from "react-bootstrap";
import { Button } from "../button/Button";

// Constants
import { COMMON_TEXT } from "constants/Common";

// styles
import "./styles.scss";

const formatContent = (content) => {
  if (!content) return '';
  
  return content.split('\n').map((text, index) => {
    // Handle bold tags
    const parts = text.split(/<b>|<\/b>/);
    
    return (
      <React.Fragment key={index}>
        {parts.map((part, partIndex) => (
          partIndex % 2 === 1 ? <b key={partIndex}>{part}</b> : part
        ))}
        {index < content.split('\n').length - 1 && <div className="newline" />}
      </React.Fragment>
    );
  });
};

export const PopupConfirm = (props) => {
  const {
    isShow = false,
    handleClose,
    handleSubmit,
    textConfirm,
    textReject,
    content,
    type = "confirm",
    isDeleteUser,
    title,
    handleReject,
    isBackdropStatic = false,
  } = props;

  return (
    <Modal
      className={
        isDeleteUser
          ? "popup-confirm-dialog-user"
          : "popup-confirm-dialog popup-confirm-dialog-entity"
      }
      show={isShow}
      onHide={handleClose}
      backdrop={isBackdropStatic ? "static" : true}
    >
      <Modal.Header className="modal-header-custom" closeButton>
        <Modal.Title>{title || COMMON_TEXT.notification}</Modal.Title>
      </Modal.Header>
      <div className="d-flex justify-content-center">
        <p className="content">
          {formatContent(content)}
        </p>
      </div>
      <Modal.Footer className="modal-footer-custom">
        <Button
          className="btn-secondary-md"
          name={textReject || COMMON_TEXT.cancel}
          isBorder={true}
          handleClick={textReject ? handleReject : handleClose}
        />
        <Button
          isBorder={false}
          className={type === "confirm" ? "btn-secondary-md" : "btn-warning-md"}
          type="submit"
          name={textConfirm}
          handleClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

PopupConfirm.propTypes = {
  isShow: PropTypes.bool,
  content: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  textConfirm: PropTypes.string,
  textReject: PropTypes.string,
  query: PropTypes.string,
  isDeleteUser: PropTypes.bool,
  isBackdropStatic: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  handleReject: PropTypes.func,
};
