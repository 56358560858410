import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import PropTypes from "prop-types";

// Services
import {
    getGeneralReviewQuickSelect
} from "services/GeneralReviewService";

// Components
import QuickFilter from "components/shared/quick-filter/QuickFilter";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";

// Constants
import {
    QUICK_SELECT_ITEM,
    COMMON_TEXT
} from "constants/Common";

// Store
import { setScopeFilter } from "store/SearchTermsReducer";

// Styles
import styles from "./ScopeFilter.module.scss";

// Helpers
import {
    convertContact,
    convertFilter,
    convertTypeToObject,
    getFilterLabel,
} from "helpers/CommonHelper";

const ScopeFilter = ({
  dateRangeData = {},
  handleGetScope = async () => {},
  handleSetScope = async () => {}
}) => {
    const { projectId } = useParams();
    const dispatch = useDispatch();

    const [quickFilterData, setQuickFilterData] = useState([]);
    const [quickFilterSelectedData, setQuickFilterSelectedData] = useState([]);
    const [filterLoading, setFilterLoading] = useState(false);

    const [key, setKey] = useState(0);

    const [showConfirmUpdatePopup, setShowConfirmUpdatePopup] = useState(false);
    const onShowConfirmUpdatePopup = () => setShowConfirmUpdatePopup(true);
    const onHideConfirmUpdatePopup = () => setShowConfirmUpdatePopup(false);
    
    const {
        scopeFilter = {},
        scopeValue = {}
    } = useSelector((state) => state.searchTerms);

    const fetchQueryTypes = async () => {
        setFilterLoading(true);
        try {
            const { data } = await getGeneralReviewQuickSelect(projectId);
            dispatch(
                setScopeFilter({
                    dataSources: get(data, "custodianSources", []),
                    dataTypes: get(data, "dataTypes", []),
                    people: get(data, "entities", [])
                })
            );
        } catch (error) {
            console.log(error);
        } finally {
            setFilterLoading(false);
        }
    };

    const setScope = async () => {
        setFilterLoading(true);
        await handleSetScope({...quickFilterSelectedData, startDate: scopeValue.dateTimeDataStart, endDate: scopeValue.dateTimeDataEnd, timeZone: scopeValue.timeZone, includeBlankDate: scopeValue.includeBlankDate});
        setFilterLoading(false);
    }

    const resetComponent = () => {
        setKey((prevKey) => prevKey + 1);
        dispatch(dateRangeData.setDateRange(dateRangeData.originalDates));
    };

    const getDataQuickFilter = () => {
        const { dataSources, dataTypes, people } = scopeFilter;

        let scopeDataSources = scopeValue.dataSources 
            ? dataSources.filter(dataSource => scopeValue.dataSources.includes(dataSource.dataSourceId.toString()))
            : null;

        setQuickFilterData([
            {
                filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataSources.value),
                filterType: QUICK_SELECT_ITEM.dataSources.value,
                filterData: {
                    dataSelected: convertFilter(
                    scopeDataSources ?? [],
                    QUICK_SELECT_ITEM.dataSources.value
                    ),
                    dataUnSelect: convertFilter(
                    dataSources,
                    QUICK_SELECT_ITEM.dataSources.value
                    ),
                },
            },
            {
                filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataTypes.value),
                filterType: QUICK_SELECT_ITEM.dataTypes.value,
                filterData: {
                    dataSelected: convertTypeToObject(dataTypes, scopeValue.dataTypes ?? []),
                    dataUnSelect: dataTypes,
                },
            },
            {
                filterLabel: getFilterLabel(QUICK_SELECT_ITEM.entities.value),
                filterType: QUICK_SELECT_ITEM.entities.value,
                filterData: {
                    dataSelected: convertFilter(
                    convertContact(people, scopeValue.people ?? []),
                    QUICK_SELECT_ITEM.entities.value
                    ),
                    dataUnSelect: convertFilter(
                    people,
                    QUICK_SELECT_ITEM.entities.value
                    ),
                },
            }
        ]);
    };

    const confirmUpdatePopup = {
        value: 2,
        content: "This operation will require you to re-run all search terms. Are you sure you want to continue?",
        textConfirm: COMMON_TEXT.confirm,
        textReject: COMMON_TEXT.cancel,
        type: "confirm",
        handleSubmit: () => {
            onHideConfirmUpdatePopup();
            setScope();
        },
        handleReject: () => {
            onHideConfirmUpdatePopup();
            setQuickFilterSelectedData([]);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await handleGetScope();
        };
        
        fetchData();
    }, []);

    useEffect(() => {
        getDataQuickFilter();
    }, [scopeValue, scopeFilter]);

    useEffect(() => {
        fetchQueryTypes();
    }, [projectId]);
      
    return (
        <>
            <PopupConfirm
                isShow={showConfirmUpdatePopup}
                handleClose={() => onHideConfirmUpdatePopup()}
                handleSubmit={confirmUpdatePopup.handleSubmit}
                content={confirmUpdatePopup.content}
                textConfirm={confirmUpdatePopup.textConfirm}
                type={confirmUpdatePopup.type}
                textReject={confirmUpdatePopup.textReject}
                handleReject={confirmUpdatePopup.handleReject}
            />
            <div className={styles["scope-filter-container"]}>
                <QuickFilter
                    data={quickFilterData}
                    dateRangeData={dateRangeData}
                    loading={filterLoading}
                    onApply={(data) => {
                        setQuickFilterSelectedData(data);
                        onShowConfirmUpdatePopup();
                    }}
                    onReset={resetComponent}
                    title="Scope Filter"
                    key={key}
                    disableActions={false}
                />
            </div>
        </>
    );
};

ScopeFilter.propTypes = {
  dateRangeData: PropTypes.object,
  handleGetScope: PropTypes.func,
  handleSetScope: PropTypes.func,
};

export default ScopeFilter;
