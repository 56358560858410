import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sourceMatterDateLoading: true,
  editMatterDateLoading: false,
  matterDateRange: {
    startDate: null,
    endDate: null,
    timeZone: null
  }
};

const matterDateRangeSlice = createSlice({
    name: "matterDate",
    initialState,
    reducers: {
      setSourceMatterDateLoading: (state, action) => {
        state.sourceMatterDateLoading = action.payload;
      },
      setEditMatterDateLoading: (state, action) => {
        state.editMatterDateLoading = action.payload;
      },
      updateMatterDateRange: (state, action) => {
        state.matterDateRange = {
          ...state.matterDateRange,
          ...action.payload,
        };
      },
      clearMatterDateRange: () => initialState,
    },
  });
  
  export const {
    setSourceMatterDateLoading,
    setEditMatterDateLoading,
    updateMatterDateRange,
    clearMatterDateRange
  } = matterDateRangeSlice.actions;
  export const MatterDateReducer = matterDateRangeSlice.reducer;
  