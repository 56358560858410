import { toThousandsNumber, formatBytes } from "./FormatterHelper";
import { COMMON_TEXT } from "../constants/Common";
import { formatDateTime } from "./DateTimeFormatterHelper";
import { DATE_TIME_TYPE } from "../constants/Constants";

export const formatNumber = (value) =>
  value ? toThousandsNumber(value) : COMMON_TEXT.default;

export const formatDateTimeSource = (timeResult) =>
  timeResult
    ? formatDateTime({
      dateTime: timeResult,
      type: DATE_TIME_TYPE.MM_DD_YYYY,
    })
    : COMMON_TEXT.default;

export const calculatePercentage = (difference, total) => {
  if (!total) return 0;
  return ((difference / total) * 100).toFixed(2);
};

export const getDifference = (stat1, stat2) => {
  const difference = stat1 - stat2;
  return difference > 0 ? difference : null;
};

export const getItemReductions = (messageStats, emailStats) => {
  const messageDiff = getDifference(
    messageStats?.messageCount,
    messageStats?.dedupeMessageCount
  );
  const emailDiff = getDifference(
    emailStats?.emailCount,
    emailStats?.dedupeEmailCount
  );
  const attachmentDiff = getDifference(
    messageStats?.attachmentCount + emailStats?.attachmentCount,
    messageStats?.dedupeAttachmentCount + emailStats?.dedupeAttachmentCount
  );
  return messageDiff + emailDiff + attachmentDiff;
}

export const getRecordReductions = (stats) => {
  const chatGroupDiff = getDifference(
    stats?.messageStats?.chatGroupCount,
    stats?.messageStats?.dedupeChatGroupCount
  );
  const emailThreadDiff = getDifference(
    stats?.emailStats?.emailThreadCount,
    stats?.emailStats?.dedupeEmailThreadCount
  );

  return chatGroupDiff ?? 0 + emailThreadDiff ?? 0;
};

export const getPreDedupeRecordCount = (stats) => {
  return (stats?.streemsCount ?? 0) + getRecordReductions(stats);
};

export const getPreDedupeItemCount = (stats) => {
  return stats?.messageStats?.messageCount + stats?.emailStats?.emailCount + (stats?.messageStats?.attachmentCount + stats?.emailStats?.attachmentCount);
};

export const formatReductionWithPercentage = (difference, total) => {
  if (!difference || !total || difference === 0 || total === 0) {
    return COMMON_TEXT.default;
  }

  return `${formatNumber(difference)} (${calculatePercentage(difference, total)}%)`;
};
  
export const formatReductionWithPercentageWithBytes = (difference, total) => {
  if (!difference || !total || difference === 0 || total === 0) {
    return COMMON_TEXT.default;
  }

  return `${formatBytes(difference)} (${calculatePercentage(difference, total)}%)`;
};

export const getSizeReductions = (messageStats, emailStats) => {
  const messageSizeDiff = getDifference(
    messageStats?.attachmentFileSize,
    messageStats?.dedupeAttachmentFileSize
  );
  const emailSizeDiff = getDifference(
    emailStats?.attachmentFileSize,
    emailStats?.dedupeAttachmentFileSize
  );
  return (messageSizeDiff ?? 0) + (emailSizeDiff ?? 0);
};

export const getTotalPreDedupeSize = (stats) => {
  return (stats?.messageStats?.attachmentFileSize ?? 0) + 
         (stats?.emailStats?.attachmentFileSize ?? 0);
};
