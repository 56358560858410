import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Button } from "../button/Button";
import QuickFilterGroup from "../filter-group/QuickFilterGroup";
import SpinnerLoading from "../spinner-loading/SpinnerLoading";
import { DateRangeModal } from "../date-range-modal/DateRangeModal";

// Constants & Helper
import { QUICK_SELECT_ITEM } from "constants/Common";
import { sortAlphabet } from "helpers/CommonHelper";

// Styles
import styles from "./QuickFilter.module.scss";
import { setSearchContextQuickFilter } from "store/MassTagReducer";

// Convert filter data that parent component will receive
const convertData = (array = [], isReset = false) => {
  return array.map((item) => ({
    filterType: item.filterType,
    filterSelected: isReset ? [] : item.filterData.dataSelected,
  }));
};

const QuickFilter = ({
  data = [],
  dateRangeData = {},
  onApply = () => {},
  onReset = () => {},
  loading = false,
  totalDisable = 0,
  title = "Quick Filter",
  disableActions = false,
}) => {
  const util = require('lodash');
  const dispatch = useDispatch();

  const { searchContext } = useSelector((state) => state.massTag);

  // Data selected
  const [dataSelected, setDataSelected] = useState([]);
  const [isReset, setIsReset] = useState(0);
  const [disable, setDisable] = useState(false);

  const [dateRangeDisplayString, setDateRangeDisplayString] = useState("");
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const onShowDateRangeFilter = () => setShowDateRangeFilter(true);
  const onHideDateRangeFilter = () => setShowDateRangeFilter(false);

  // Contacts related together
  const contactValues = [
    QUICK_SELECT_ITEM.entities.value,
    QUICK_SELECT_ITEM.participants.value,
  ];

  // Return disable with filterType
  const disableChecked = (filterType) => contactValues.includes(filterType);

  const checkExistData = (array = [], value) =>
    array.filter((item) => item?.value === value).length;

  // Check if the passed in data is equal to the currently selected data
  const noDataSelected = () => {
    const normalizedFilterData = data.map((element) => ({
      dataSelected: element.filterData.dataSelected
    }));
    const normalizedFilterDataSelected = dataSelected.map((element) => ({
      dataSelected: element.filterSelected
    }));

    return util.isEqual(normalizedFilterData.sort(), normalizedFilterDataSelected.sort());
  }

  // Update data select
  const getDataSelect = (dataSelect, filterType) => {
    const dataNew = dataSelected.map((item) =>
      item.filterType === filterType
        ? { ...item, filterSelected: dataSelect }
        : item
    );

    // Update dataSelected with by filter type
    setDataSelected(dataNew);
    if (totalDisable === 0) return;
    let contactTotal = 0;
    dataNew.forEach((item) => {
      if (disableChecked(item.filterType))
        contactTotal += item.filterSelected.length;
    });
    // set disable item by totalDisable prop
    if (disableChecked(filterType)) setDisable(contactTotal >= totalDisable);
  };

  const onResetFilter = () => {
    // Change isRest to trigger function reset
    setIsReset(isReset + 1);
    // Convert data before reset
    const dataReset = convertData(data, true);
    // Reset data = []
    onReset(dataReset);
    setDisable(false);
    setDataSelected(dataReset);
  };

  const handleApply = () => {
    const dataResult = {};
    dataSelected.forEach((item) => {
      dataResult[item.filterType] = item.filterSelected;
    });
    onApply(dataResult);
  };

  const generateDisplayString = (startDate, endDate) => {
    switch (true) {
        case startDate !== null && endDate !== null:
            return `${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format('MM/DD/YYYY')}`;
        case startDate !== null:
            return `After ${moment(startDate).format('MM/DD/YYYY')}`;
        case endDate !== null:
            return `Before ${moment(endDate).format('MM/DD/YYYY')}`;
        default:
            return "No Date Scope Applied";
    }
  };

  useEffect(() => {
    const displayString = generateDisplayString(dateRangeData?.dateTimeDataStart, dateRangeData?.dateTimeDataEnd)
    setDateRangeDisplayString(displayString);
  }, [dateRangeData]);

  // Update data selected when data change
  useEffect(() => {
    setDataSelected(convertData(data));
    // Disable item with totalDisable when totalDisable > 0
    if (totalDisable === 0) return;
    let contactTotal = 0;
    if (data.length > 0) {
      data.forEach((item) => {
        if (disableChecked(item.filterType))
          contactTotal += item.filterData.dataSelected.length;
      });
    }
    setDisable(contactTotal === totalDisable);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const dataResult = {};
    dataSelected.forEach((item) => {
      dataResult[item.filterType] = item.filterSelected;
    });
    dispatch(setSearchContextQuickFilter({
      ...searchContext.quickFilter,
      dataSourceIds: dataResult.dataSources?.map((source) => source.value) ?? [],
      dataTypes: dataResult.dataTypes?.map((type) => type.value) ?? [],
      tags: dataResult.tags?.map((tag) => tag.value) ?? [],
      entities: dataResult.entities?.map((entity) => entity.value) ?? [],
      participants: dataResult.participants?.map((participant) => participant.value) ?? [],
      // Maybe set here
    }));
  }, [dataSelected])

  return (
    <>
      <DateRangeModal
        isShow={showDateRangeFilter}
        onHide={onHideDateRangeFilter}
        onSubmit={() => {}}
        updateDateState={dateRangeData?.setDateRange}
        data={{ 
          existingStartDate: dateRangeData?.dateTimeDataStart, 
          existingEndDate: dateRangeData?.dateTimeDataEnd,
          timeZone: dateRangeData?.timeZone,
          includeBlankDate: dateRangeData?.includeBlankDate
        }}
      />
      <div className={styles["wrap"]} id="quick-filter">
        <div className={styles["filter-control"]}>
          <p className={styles["title"]}>{title}</p>
          <div className={styles["btn-group"]}>
            <div className={styles["btn-reset"]}>
              <Button
                isDisabled={data.length === 0 || (disableActions && noDataSelected())}
                handleClick={onResetFilter}
                name="Reset"
                className="btn-secondary"
                isBorder
              />
            </div>
            <Button
              isDisabled={data.length === 0 || (disableActions && noDataSelected())}
              handleClick={handleApply}
              isBorder={false}
              name="Apply"
              className="btn-secondary"
            />
          </div>
        </div>
        <div className={styles["filter-contain"]}>
          {loading ? (
            <SpinnerLoading />
          ) : (
            <>
              {dateRangeData !== null && Object.keys(dateRangeData).length > 0 &&
                <div className={styles["date-range-control"]}>
                  <div className={styles["date-range-label"]}>
                    <span>Date Range</span>
                  </div>
                  <div className={styles["date-range-container"]}>
                    <p 
                      className={`${styles["date-range-text"]} ${
                        !dateRangeData.dateTimeDataStart && !dateRangeData.dateTimeDataEnd
                          ? styles["gray-text"]
                          : ""
                      }`}
                    >
                      {dateRangeDisplayString}
                    </p>                    
                    <div className={styles["date-range-icon"]}>
                      <div className="mar_r10 cursor-pointer">
                        <img
                          src={
                            dateRangeData.dateTimeDataStart !== null ||
                            dateRangeData.dateTimeDataEnd !== null
                              ? "/images/calendar-icon-checked.svg"
                              : "/images/calendar-icon.svg"
                          }
                          alt="icon"
                          onClick={onShowDateRangeFilter}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              {data.length > 0 &&
                data.map((item) => (
                  <QuickFilterGroup
                    key={item.filterType}
                    // Entity, Participant and other will have not suggestion
                    isSuggestion={!contactValues.includes(item.filterType)}
                    filterLabel={item.filterLabel}
                    placeholder={item.filterLabel}
                    totalDataSelected={sortAlphabet(item.filterData.dataSelected)}
                    totalDataUnSelect={sortAlphabet(
                      item.filterData.dataUnSelect.filter(
                        (filter) =>
                          !checkExistData(
                            item.filterData.dataSelected,
                            filter.value
                          )
                      )
                    )}
                    getDataSelect={(dataSelected) =>
                      getDataSelect(dataSelected, item.filterType)
                    }
                    isReset={isReset}
                    isEmoji={
                      !!(QUICK_SELECT_ITEM.emojis.value === item.filterType)
                    }
                    disableSelectAll={
                      !!(totalDisable > 0 && disableChecked(item.filterType))
                    }
                    totalData={item.filterData.dataUnSelect}
                    disable={
                      totalDisable > 0 && disableChecked(item.filterType)
                        ? disable
                        : false
                    }
                    notSelectedOption={item.notSelectedOption}
                  />
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

QuickFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      filterLabel: PropTypes.shape({
        label: PropTypes.string,
        labelShort: PropTypes.string,
      }),
      filterType: PropTypes.string,
      filterData: PropTypes.shape({
        dataSelected: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            sourceType: PropTypes.string,
          })
        ),
        dataUnSelect: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            sourceType: PropTypes.string,
          })
        ),
      }),
      notSelectedOption: PropTypes.shape({
        label: PropTypes.string,
        onSelectCallback: PropTypes.func,
      }),
    })
  ),
  dateRangeData: PropTypes.object,
  loading: PropTypes.bool,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  totalDisable: PropTypes.number,
  title: PropTypes.string,
  disableActions: PropTypes.bool,
};

export default QuickFilter;
