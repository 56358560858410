import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// Stores
import {
  resetStoreReview,
  setParamSearchReview,
} from "store/GeneralReviewReducer";
import { sourceDetailInit } from "store/DataSourceReducer";

// Constants
import { DATA_SOURCE_TYPES } from "constants/Constants";
import { COMMON_TEXT, PATH_NAME } from "constants/Common";
import { IMPORT_PROCESS, JOB_CATEGORY } from "constants/DataSourceConstant";

//Components
import { Button } from "components/shared/button/Button";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import DataSourceCardItem from "./DataSourceCardItem";

// Hook
import { useToken } from "hook/auth";

//Helpers
import { formatBytes } from "helpers/FormatterHelper";
import { getTypeIconSrc } from "helpers/CommonHelper";
import {
  getItemReductions,
  formatDateTimeSource,
  formatNumber,
  getRecordReductions,
  getPreDedupeRecordCount,
  getPreDedupeItemCount,
  formatReductionWithPercentage,
  getSizeReductions,
  getTotalPreDedupeSize,
  formatReductionWithPercentageWithBytes,
} from "helpers/DataSourceCardHelper";

//Styles
import styles from "./DataSourceCard.module.scss";

const DataSourceCard = ({
  isLoading = false,
  sourceDetail = sourceDetailInit,
  onViewLog = () => {},
  totalRecord = 0,
  disableBtnViewLog = false,
  hiddenBtnViewLog = false,
  onEditSource,
}) => {
  const { isUser } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const {
    importedDate,
    analystName,
    custodianName,
    dataSourceDescription,
    dataSourceType,
    identifier,
    sourceId,
    jobCategory,
    stats,
    nativeStatus,
  } = sourceDetail;

  const goToReviewScreen = (source = {}) => {
    const {
      custodianName = "",
      dataSourceId = 0,
      dataSourceType = "",
      dataSourceDescription = "",
    } = source;
    const dataSources = [
      {
        custodianName: `${dataSourceDescription} - ${custodianName}`,
        dataSourceId,
        dataSourceType,
      },
    ];
    dispatch(resetStoreReview());
    dispatch(setParamSearchReview({ dataSources }));
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.streems}`, {
      state: { fromScreen: PATH_NAME.dataSources },
    });
  };  

  const hideSizeStats = (stats) => {
    return (stats?.messageStats?.attachmentFileSize === null
     || stats?.messageStats?.attachmentFileSize === 0)
     && (stats?.emailStats?.attachmentFileSize === null 
     || stats?.emailStats?.attachmentFileSize === 0);
  };

  const sourceSummaryItems = [
    {
      label: "Date Added",
      value: formatDateTimeSource(importedDate),
    },
    {
      label: "Analyst",
      value: analystName || COMMON_TEXT.default,
      fwBold: true,
    },
    {
      label: "Records Identified",
      value: formatNumber(getPreDedupeRecordCount(stats)),
      fwBold: true
    },
    {
      label: "Records Imported",
      value: formatNumber(stats?.streemsCount),
      fwBold: true,
      children: [
        {
          label: "Parent Item Count",
          value: formatNumber(stats?.messageStats?.dedupeMessageCount + stats?.emailStats?.dedupeEmailCount),
        },
        {
          label: "Attachment Item Count",
          value: formatNumber(stats?.messageStats?.dedupeAttachmentCount + stats?.emailStats?.dedupeAttachmentCount),
        },
        {
          label: "Attachment Size",
          value: hideSizeStats(stats) ? COMMON_TEXT.default : formatBytes(stats?.messageStats?.dedupeAttachmentFileSize + stats?.emailStats?.dedupeAttachmentFileSize),
        },
        {
          label: "Natives Imported",
          value: nativeStatus === IMPORT_PROCESS.completed.value ? "Yes" : "No",
        }
      ]
    },
  ];

  const reductionSummaryItems = [
    {
      label: "Duplicates Removed",
      value: null,
      fwBold: true,
      children: [
        {
          label: "Records",
          value: formatReductionWithPercentage(
            getRecordReductions(stats), 
            getPreDedupeRecordCount(stats)
          )
        },
        {
          label: "Items",
          value: formatReductionWithPercentage(
            getItemReductions(stats?.messageStats, stats?.emailStats),
            getPreDedupeItemCount(stats)
          )
        },
        {
          label: "Size",
          value: formatReductionWithPercentageWithBytes(
            getSizeReductions(stats?.messageStats, stats?.emailStats),
            getTotalPreDedupeSize(stats)
          )
        }
      ]
    }
  ];

  const importInventoryItems = [
    {
      label: "Chats",
      value: formatNumber(stats?.messageStats?.dedupeChatGroupCount),
      fwBold: true,
      children: [
        {
          label: "Messages",
          value: formatNumber(stats?.messageStats?.dedupeMessageCount),
        },
        {
          label: "Attachments",
          value: formatNumber(stats?.messageStats?.dedupeAttachmentCount),
        },
      ],
    },
    {
      label: "Email Threads",
      value: formatNumber(stats?.emailStats?.dedupeEmailThreadCount),
      fwBold: true,
      children: [
        {
          label: "Emails",
          value: formatNumber(stats?.emailStats?.dedupeEmailCount),
        },
        {
          label: "Attachments",
          value: formatNumber(stats?.emailStats?.dedupeAttachmentCount),
        }
      ],
    },
    {
      label: "Images",
      value: formatNumber(stats?.looseFileStats?.imageCount),
    },
    {
      label: "Videos",
      value: formatNumber(stats?.looseFileStats?.videoCount),
    },
    {
      label: "Audio",
      value: formatNumber(stats?.looseFileStats?.audioCount),
    },
    {
      label: "Documents",
      value: formatNumber(stats?.looseFileStats?.fileCount),
    },
    {
      label: "Notes",
      value: formatNumber(stats?.looseFileStats?.noteCount),
    },
    {
      label: "Database Files",
      value: formatNumber(stats?.looseFileStats?.databaseFileCount),
    },
    {
      label: "Browsing Histories",
      value: formatNumber(stats?.looseFileStats?.historyLinkCount),
    },
    {
      label: "Locations",
      value: formatNumber(stats?.looseFileStats?.locationCount),
    },
    {
      label: "Network Connections",
      value: formatNumber(stats?.looseFileStats?.networkCount),
    },
    {
      label: "Financial Info",
      value: formatNumber(stats?.looseFileStats?.creditCardCount),
    },
    {
      label: "Voicemails",
      value: formatNumber(stats?.looseFileStats?.voicemailCount),
    },
    {
      label: "Call Logs",
      value: formatNumber(stats?.looseFileStats?.callLogCount),
    },
    {
      label: "Contacts",
      value: formatNumber(stats?.looseFileStats?.contactCount),
    },
    {
      label: "Installed Apps",
      value: formatNumber(stats?.installedApps?.length),
    },
  ];

  return (
    <div className={styles["wrap"]}>
      <div className={styles["source-item"]}>
        {isLoading ? (
          <SpinnerLoading />
        ) : sourceDetail && totalRecord > 0 ? (
          <Fragment>
            <div className={styles["source-item-header"]}>
              <div className={styles["source-name"]}>
                <div
                  className={clsx(
                    styles["source-info-img"],
                    jobCategory === JOB_CATEGORY.draft.label
                      ? styles["source-info-img-none"]
                      : ""
                  )}
                >
                  <img src="/images/custodian-icon.svg" alt="Custodian" />
                </div>
                <p className={styles["source-info-name"]} title={custodianName}>
                  {custodianName}
                </p>
              </div>
              {!isUser() && (
                <img
                  src="/images/icons/edit-icon-blue.svg"
                  alt="Custodian"
                  onClick={onEditSource}
                />
              )}
            </div>
            <div className={styles["source-item-body"]}>
              <div className={styles["source-device"]}>
                <p className={styles["source-type"]}>
                  <img
                    src={getTypeIconSrc(DATA_SOURCE_TYPES, dataSourceType)}
                    alt="Data source type"
                  />
                  <span>{dataSourceType}</span>
                </p>
                <div className={styles["source-id"]}>
                  <span>{sourceId}</span>
                </div>
                <p
                  className={styles["source-description"]}
                  title={dataSourceDescription}
                >
                  {dataSourceDescription}
                </p>
                <p className={styles["identifier"]} title={identifier}>
                  {identifier}
                </p>
              </div>
              <div
                className={clsx(styles["source-info"])}
              >
                <DataSourceCardItem
                  header="Source Summary"
                  items={sourceSummaryItems}
                />
                <DataSourceCardItem
                  header="Reduction Summary"
                  items={reductionSummaryItems}
                />
                <DataSourceCardItem
                  header="Import Inventory"
                  items={importInventoryItems}
                  installedApps={stats?.installedApps}
                />
              </div>
            </div>
            <div className={styles["source-item-footer"]}>
              {!hiddenBtnViewLog && (
                <Button
                  name="View Log"
                  className="btn-medium"
                  handleClick={onViewLog}
                  isDisabled={disableBtnViewLog}
                />
              )}
              <Button
                isDisabled={jobCategory === JOB_CATEGORY.draft.label}
                name="Go to source"
                className="btn-medium-blue"
                handleClick={() => goToReviewScreen(sourceDetail)}
              />
            </div>
          </Fragment>
        ) : (
          <div className={styles["no-result"]}>
            <EmptyPage
              messages="Please select a Data Source to view details"
              size="md"
            />
          </div>
        )}
      </div>
    </div>
  );
};

DataSourceCard.propTypes = {
  isLoading: PropTypes.bool,
  disableBtnViewLog: PropTypes.bool,
  hiddenBtnViewLog: PropTypes.bool,
  totalRecord: PropTypes.number,
  onEditSource: PropTypes.func,
  sourceDetail: PropTypes.shape({
    analystName: PropTypes.string,
    importedDate: PropTypes.string,
    custodianName: PropTypes.string,
    dataSourceDescription: PropTypes.string,
    dataSourceType: PropTypes.string,
    sourceId: PropTypes.string,
    identifier: PropTypes.string,
    jobCategory: PropTypes.string,
    importProcess: PropTypes.number,
    nativeStatus: PropTypes.number,
    stats: PropTypes.shape({
      streemsCount: PropTypes.number,
      messageStats: PropTypes.shape({
        messageCount: PropTypes.number,
        chatGroupCount: PropTypes.number,
        attachmentCount: PropTypes.number,
      }),
      emailStats: PropTypes.shape({
        emailCount: PropTypes.number,
        emailThreadCount: PropTypes.number,
        attachmentCount: PropTypes.number,
      }),
      looseFileStats: PropTypes.shape({
        imageCount: PropTypes.number,
        videoCount: PropTypes.number,
        audioCount: PropTypes.number,
        fileCount: PropTypes.number,
        noteCount: PropTypes.number,
        databaseFileCount: PropTypes.number,
        historyLinkCount: PropTypes.number,
        locationCount: PropTypes.number,
        networkCount: PropTypes.number,
        creditCardCount: PropTypes.number,
        voicemailCount: PropTypes.number,
        callLogCount: PropTypes.number,
        contactCount: PropTypes.number,
      }),
      installedApps: PropTypes.array,
    }),
  }),
  getStatusSource: PropTypes.func,
  onViewLog: PropTypes.func,
};

export default DataSourceCard;
