import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
Modal,
ModalBody,
ModalFooter,
ModalHeader,
} from "react-bootstrap";

// Components
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { startOfDay, endOfDay } from 'date-fns';
import { fromZonedTime, toZonedTime, format } from "date-fns-tz";
import { DropdownSelector } from "components/shared/dropdown-selector/DropdownSelector";
import { Button } from "components/shared/button/Button";
import { useDispatch } from "react-redux";

// Constants
import { DATE_SELECTOR, TIME_ZONES } from "constants/DateSelector";

// Styles
import styles from "./DateRangeModal.module.scss";

const DateRangeModal = (props) => {
  const {
    isShow = false,
    onHide = () => {},
    onSubmit = () => {},
    showBlankDateSelector = true,
    updateDateState,
    data,
  } = props;

  const dispatch = useDispatch();

  const { existingStartDate, existingEndDate, timeZone, includeBlankDate = false } = data;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [utcStartDate, setUtcStartDate] = useState();
  const [utcEndDate, setUtcEndDate] = useState();
  const [saveState, setSaveState] = useState(false);
  
  const [currentTimezone, setCurrentTimezone] = useState(
    timeZone
      ? TIME_ZONES.find((timezone) => timezone.value === timeZone)
      : TIME_ZONES.find((timezone) => timezone.value === "Etc/UTC")
  );  

  const [selectedTimezone, setSelectedTimezone] = useState(currentTimezone); 

  const [currentIncludeBlank, setCurrentIncludeBlank] = useState(includeBlankDate);
  const [selectedIncludeBlank, setSelectedIncludeBlank] = useState(includeBlankDate);

  const hideResetButton = (
    startDate === null 
    && endDate === null 
    && currentTimezone === TIME_ZONES.find((timezone) => timezone.value === "Etc/UTC") 
    && currentIncludeBlank === false
  )

  const handleConvert = (data) => {
    const timeZone = TIME_ZONES.find((timezone) => timezone.value === data?.value);
    setCurrentTimezone(timeZone)
  };

  const handleSubmit = async () => {
    let typeFilter; 

    if (utcStartDate && utcEndDate)
      typeFilter = DATE_SELECTOR.type.between;
    else if (utcStartDate)
      typeFilter = DATE_SELECTOR.type.after;
    else if (utcEndDate)
      typeFilter = DATE_SELECTOR.type.before;

    const dateTimeSubmit = {
      dateTimeDataStart: utcStartDate,
      dateTimeDataEnd: utcEndDate,
      timeZone: currentTimezone.value,
      includeBlankDate: currentIncludeBlank,
      typeFilter: typeFilter,
    };

    if (typeof updateDateState === 'function')
      dispatch(updateDateState(dateTimeSubmit));

    onSubmit(dateTimeSubmit);

    setSelectedTimezone(currentTimezone);
    setSelectedIncludeBlank(currentIncludeBlank);
    setSaveState(true);

    onHide();
  };

  const handleStartDateChange = (e) => {
    if (e.value) {
      const selectedDate = new Date(e.value);
      selectedDate.setHours(0, 0, 0);
      setStartDate(selectedDate);
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = (e) => {
    if (e.value) {
      const selectedDate = new Date(e.value);
      selectedDate.setHours(23, 59, 59);
      setEndDate(selectedDate);
    } else {
      setEndDate(null);
    }
  };

  const onHideModal = () => { 
    if (!saveState && !existingStartDate && !existingEndDate) {
      setCurrentTimezone(TIME_ZONES.find((timezone) => timezone.value === "Etc/UTC"));
      setStartDate(null);
      setEndDate(null);
    }

    if (existingStartDate || existingEndDate) {
      const convertedDates = convertToTimeZone(existingStartDate, existingEndDate, timeZone);
      setStartDate(convertedDates?.startDate);
      setEndDate(convertedDates?.endDate);
    }

    setCurrentTimezone(selectedTimezone ?? currentTimezone);
    setCurrentIncludeBlank(selectedIncludeBlank ?? currentIncludeBlank);

    onHide(); 
  };

  const isUTCDate = (dateString) => {
    return typeof dateString === 'string' && dateString.endsWith('Z');
  };
  
  const convertUTCToTimeZone = (utcDateString, timeZone) => {
    if (!utcDateString) return null;

    const zonedDate = toZonedTime(utcDateString, timeZone);
    const formattedDate = format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss", { timeZone });

    return formattedDate;
  };

  const resetData = () => {
    setStartDate(null);
    setEndDate(null);
    setCurrentTimezone(TIME_ZONES.find((timezone) => timezone.value === "Etc/UTC"));
    setCurrentIncludeBlank(false);
  }

  const convertToTimeZone = (startDate, endDate, timeZone) => {
    const existingTimeZone = timeZone || currentTimezone?.value;
  
    const adjustDate = (date) => {
      if (!date) return null;
      const adjustedDate = isUTCDate(date)
        ? convertUTCToTimeZone(date, existingTimeZone)
        : new Date(date);
      return adjustedDate;
    };
  
    const formatDate = (date, formatter) => {
      if (!date) return null;
      return format(formatter(date), "yyyy-MM-dd'T'HH:mm:ss");
    };
  
    return {
      startDate: formatDate(adjustDate(startDate), startOfDay),
      endDate: formatDate(adjustDate(endDate), endOfDay),
    };
  };  

  useEffect(() => {
    setCurrentIncludeBlank(includeBlankDate);
    setSelectedIncludeBlank(includeBlankDate);
  }, [includeBlankDate]);

  useEffect(() => {
    const requestedTimeZone = TIME_ZONES.find((timezone) => timezone.value === timeZone);

    if (requestedTimeZone) {
      setCurrentTimezone(requestedTimeZone);
      setSelectedTimezone(requestedTimeZone);
    }
  }, [timeZone]);
  
  useEffect(() => {
    const convertedDates = convertToTimeZone(existingStartDate, existingEndDate, timeZone);
    setStartDate(convertedDates?.startDate);
    setEndDate(convertedDates?.endDate);
  }, [existingStartDate, existingEndDate]);

  useEffect(() => {
    setUtcStartDate(startDate ? fromZonedTime(startDate, currentTimezone?.value)?.toISOString() : null)
    setUtcEndDate(endDate ? fromZonedTime(endDate, currentTimezone?.value)?.toISOString(): null);
  }, [startDate, endDate, currentTimezone]);

  return (
    <Modal
    show={isShow}
    onHide={onHide}
    centered
    backdrop="static"
    className={styles["wrap"]}
    >
      <ModalHeader className={styles["modal-header"]}>
          <h3 className={styles["title"]}>
            {"Date Filter"}
          </h3>
        <div onClick={onHideModal} className={styles["close-icon"]}>
          <img src="/images/close-modal-icon.png" alt="Close" />
        </div>
      </ModalHeader>
      <ModalBody className={styles["modal-body"]}>
        <span className={styles["modal-label"]}>
          Start Date
        </span>
        <div className={styles["date-control"]}>
          <DatePickerComponent
            cssClass={styles["start-date-component"]}
            placeholder={"MM/DD/YYYY"}
            value={startDate}
            change={handleStartDateChange}
            width={311}
            max={endDate ? endDate : new Date(2099, 11, 31)}
            strictMode={true}
          />
        </div>
        <span className={styles["modal-label"]}>
          End Date
        </span>
        <div className={styles["date-control"]}>
          <DatePickerComponent
          cssClass={styles["end-date-component"]}
            placeholder={"MM/DD/YYYY"}
            value={endDate}
            change={handleEndDateChange}
            width={311}
            min={startDate ? startDate : new Date(1900, 0, 1)}
            strictMode={true}
          />
        </div>
        <span className={styles["modal-label"]}>
          Time Zone
        </span>
        <div className={styles["time-zone-control"]}>
          <div className={styles["time-zone-dropdown"]}>
            <DropdownSelector
              allowAutoScroll={false}
              size="md"
              itemList={TIME_ZONES}
              selectedItem={currentTimezone?.name}
              onSelect={handleConvert}
              className={styles["time-zone-dropdown-selector"]}
              classNameDropdown={styles["time-zone-dropdown-selector-dropdown"]}
              isNormalLetter={true}
            />
          </div>
        </div>
        {showBlankDateSelector && (
          <div className={styles["blank-date-control"]}>
            <div className={styles["blank-date-checkbox-control"]}>
              <input
                className={styles["blank-date-checkbox"]}
                type="checkbox"
                checked={currentIncludeBlank}
                onChange={() => setCurrentIncludeBlank(!currentIncludeBlank)}
              />
            </div>
            <p
              className={styles["invalid-date-text"]}
              onClick={() => setCurrentIncludeBlank(!currentIncludeBlank)}
            >
              Include Invalid/Blank Dates
            </p>
          </div>
        )}
      </ModalBody>
      <ModalFooter className={styles["modal-footer"]}>
      <div className={`${styles["btn-control"]} ${!hideResetButton ? styles["multi-button"] : ""}`}>
        {!hideResetButton && 
          <Button
            handleClick={resetData}
            name="Reset"
            className={styles["btn-reset"]}
          />
        }
        <div className={styles["btn-right-group"]}>
          <Button name="Cancel" handleClick={onHideModal} />
          <Button
            name={"Save"}
            className="btn-primary-fill"
            handleClick={handleSubmit}
          />
        </div>
      </div>
      </ModalFooter>
    </Modal>
  );  
};

DateRangeModal.propTypes = {
isShow: PropTypes.bool,
onHide: PropTypes.func,
onSubmit: PropTypes.func,
showBlankDateSelector: PropTypes.bool,
updateDateState: PropTypes.func,
data: PropTypes.object,
};

export { DateRangeModal };