import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";
import TabList from 'components/shared/tab-list/TabList';
import { ParticipantsInformation } from "components/chat-email-information/participants/ParticipantsInformation";
import WordCloud from "components/word-cloud/WordCloud";
import EmojiCloud from "components/emoji-cloud/EmojiCloud";
import DataSourceInformation from "components/chat-email-information/data-source-info/DataSourceInformation";
import Tags from "components/tags/Tags";
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { useOnClickOutside } from "hook/click-outside";

//Constants and Helpers
import { PARAMETERS } from "constants/Common";
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Styles
import styles from "./InformationTabContainer.module.scss";

const InformationTabContainer = ({ infoResultLabelList, dataType, itemInfo, filterData }) => {
  const dashboardObj = useRef();
  const actionRef = useRef();

  const LOCAL_STORAGE_KEY = "informationPanels";

  const tabs = useMemo(() => [
    ...(filterData && Object.keys(filterData).length > 0 ? [{ id: 1, name: "Summary" }] : []),
    { id: filterData ? 2 : 1, name: "General" },
    { id: filterData ? 3 : 2, name: "Extended" },
  ], [filterData]);

  const panelIDs = {
    datasource: "Data Source",
    participants: "Participants",
    tags: "Tags",
    wordcloud: "Word Cloud",
    emojicloud: "Emoji Cloud",
  };
  
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [showPanel, setShowPanel] = useState(false);

  const [availablePanels, setAvailablePanels] = useState(panelIDs);
  const [selectedPanels, setSelectedPanels] = React.useState(() => {
    const storedPanels = localStorage.getItem('informationPanels');
    return storedPanels ? JSON.parse(storedPanels) : Object.keys(panelIDs);
  });

  useOnClickOutside(actionRef, () => setShowPanel(false));
  const handleShowPanel = () =>
    setShowPanel(true);

  const isValidValue = (value) => 
    value && value !== '' && !(typeof value === 'object' && Object.keys(value).length === 0);

  const renderHeader = (label) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{label}</span>
    </div>
  );

  const onAdd = (id) => { 
    const panelToAdd = allPanels.find(panel => panel.id === id);
    if (dashboardObj.current)
      dashboardObj.current.addPanel(panelToAdd);
  }

  const onRemove = (id) => { 
    if (dashboardObj.current)
      dashboardObj.current.removePanel(id);
  }
  
  const handlePanelChange = (id) => {
    setSelectedPanels(prevSelectedPanels => {
      if (prevSelectedPanels.includes(id)) {
        onRemove(id);
        return prevSelectedPanels.filter(panelId => panelId !== id);
      } else {
        onAdd(id);
        return [...prevSelectedPanels, id];
      }
    });
  };

  const renderDataSourceContent = () => (
    <div className="e-panel-container">
      <DataSourceInformation
        isLoading={filterData.loading}
        dataSources={filterData.dataSourceInfo.getDataSourceInfo()}
      />
    </div>
  );

  const renderParticipantContent = () => (
    <div className="e-panel-container">
      <ParticipantsInformation
        isLoading={filterData.loading}
        participants={filterData.participantInfo.participants}
        isRedColor={filterData.participantInfo.isRedColor}
      />
    </div>
  );

  const renderTagContent = () => (
    <div className="e-panel-container">
      <Tags
        isLoading={filterData.loading}
        tagList={filterData.tagProps.selectTags}
        onSelectTags={filterData.tagProps.handleSelectedTagsChange}
      />
    </div>
  );

  const renderWordCloudContent = () => (
    <div className="e-panel-container">
      <WordCloud
        listConversation={filterData.wordCloudProps.listConversation}
        isLoading={filterData.loading}
        data={filterData.wordCloudProps.data}
        onClick={filterData.wordCloudProps.onClick}
      />
    </div>
  );

  const renderEmojiCloudContent = () => (
    <div className="e-panel-container">
      <EmojiCloud
        listConversation={filterData.emojiCloudProps.listConversation}
        isLoading={filterData.loading}
        data={filterData.emojiCloudProps.data}
        onClick={filterData.emojiCloudProps.onClick}
      />
    </div>
  );

  const allPanels = [
    {
      id: "datasource",
      row: 0,
      col: 0,
      cssClass: styles["panel-container"],
      header: () => renderHeader("Data Source"),
      content: renderDataSourceContent,
      condition: () => filterData?.dataSourceInfo?.getDataSourceInfo?.()?.length > 0,
    },
    {
      id: "participants",
      row: 1,
      col: 0,
      cssClass: styles["panel-container"],
      header: () => renderHeader("Participants"),
      content: renderParticipantContent,
      condition: () => filterData?.participantInfo?.participants?.length > 0,
    },
    {
      id: "tags",
      row: 2,
      col: 0,
      cssClass: styles["panel-container"],
      header: () => renderHeader("Tags"),
      content: renderTagContent,
      condition: () => filterData?.tagProps?.selectTags?.length > 0,
    },
    {
      id: "wordcloud",
      row: 3,
      col: 0,
      cssClass: styles["panel-container"],
      header: () => renderHeader("Word Cloud"),
      content: renderWordCloudContent,
      condition: () => filterData?.wordCloudProps?.data?.length > 0,
    },
    {
      id: "emojicloud",
      row: 4,
      col: 0,
      cssClass: styles["panel-container"],
      header: () => renderHeader("Emoji Cloud"),
      content: renderEmojiCloudContent,
      condition: () => filterData?.emojiCloudProps?.data?.length > 0,
    },
  ];  

  const panels = () => {
    const storedPanels = localStorage.getItem(LOCAL_STORAGE_KEY);
    var currentSelectedPanels;

    if (storedPanels) 
      currentSelectedPanels = JSON.parse(storedPanels);
    else 
      currentSelectedPanels = Object.keys(panelIDs);
    
    return allPanels
      .filter(panel => currentSelectedPanels.includes(panel.id) && panel.condition())
      .map(({ condition, ...panel }) => panel);
  };

  const renderSummaryTabContent = () => (
    <div className={styles["summary-wrap"]}>
      <DashboardLayoutComponent
        ref={dashboardObj}
        id="information-dashboard"
        cellSpacing={[10, 10]}
        allowResizing={false}
        cellAspectRatio={100 / 45}
        columns={1}
        panels={panels()}
        allowDragging={false}
      />
    </div>
  );

  const renderGeneralTabContent = () => {
    const filteredItems = infoResultLabelList?.filter(item =>
      isValidValue(item.value) || isValidValue(getInformationByKey(item.key, itemInfo))
    );

    return (
      <div className={styles["general-tab-wrap"]}>
        {filteredItems?.length > 0 ? (
          filteredItems.map((item) => (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={item.value || getInformationByKey(item.key, itemInfo)}
              isIcon={item.key === PARAMETERS.typeLabel}
              type={dataType}
            />
          ))
        ) : (
          <div className={styles["no-data"]}>No general information available</div>
        )}
      </div>
    );
  };

  const renderExtendedTabContent = () => (
    <div className={styles["extended-tab-wrap"]}>
      {itemInfo?.extendedMetadataFields?.length > 0 ? (
        itemInfo.extendedMetadataFields
          .filter((field) => isValidValue(field.fieldValue))
          .map((field) => (
            <InfoResultItem
              key={field.fieldName}
              label={field.fieldName}
              value={field.fieldValue}
              isIcon={false}
              type={dataType}
            />
          ))
      ) : (
        <div className={styles["no-data"]}>No extended information available.</div>
      )}
    </div>
  );

  const summaryContent = useMemo(() => {
    if (tabs.find(tab => tab.id === currentTab)?.name === "Summary") {
      return renderSummaryTabContent();
    }
    return null;
  }, [currentTab, filterData?.loading]);

  const renderContent = () => {
    switch (tabs.find(tab => tab.id === currentTab)?.name) {
      case "General":
        return renderGeneralTabContent();
      case "Extended":
        return renderExtendedTabContent();
      case "Summary":
        return summaryContent;
      default:
        return null;
    }
  };

  useEffect(() => {
    const activePanelIDs = allPanels
    .filter(panel => panel.condition())
    .map(panel => panel.id);

    const activePanelNames = activePanelIDs.reduce((acc, id) => {
      acc[id] = panelIDs[id];
      return acc;
    }, {});

    setAvailablePanels(activePanelNames);
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectedPanels));
  }, [selectedPanels]);

  return (
    <div className={styles["info-data-container"]}>
      <div className={styles["info-tab-list"]}>
        <TabList
          tabList={tabs}
          currentTab={{ parentTab: currentTab, childTab: null }}
          size="md"
          onClickTab={({ parentTab }) => setCurrentTab(parentTab)}
        />
        {(tabs.find(tab => tab.id === currentTab)?.name) === "Summary" && 
          <img
            className={styles["panel-checkbox-image"]}
            src="/images/icons/three-dot-menu.svg"
            alt="menu-control"
            onClick={() => handleShowPanel()}
          />
        }
      </div>
      {showPanel && 
        <div className={styles["panel-checkbox-container"]} ref={actionRef}>
          {Object.keys(availablePanels).map((panel) => (
            <div key={panel} className={styles["panel-item"]}>
              <input
                type="checkbox"
                className={styles["panel-checkbox"]}
                checked={selectedPanels.includes(panel)}
                onChange={() => handlePanelChange(panel)}
              />
              <label htmlFor={`checkbox_${panel}`} className={styles["panel-label"]}>
                <span>
                  {availablePanels[panel]}
                </span>
              </label>
            </div>
          ))}
        </div>
      }
      <div className={styles["info-result-list"]}>
        {renderContent()}
      </div>
    </div>
  );
};


InformationTabContainer.propTypes = {
  infoResultLabelList: PropTypes.array,
  dataType: PropTypes.string,
  itemInfo: PropTypes.object,
  filterData: PropTypes.object
};

export default InformationTabContainer;