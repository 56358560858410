import React, { useState } from "react";
import { Form, Formik } from "formik";
import { BeatLoader } from "react-spinners";

// Services
import { createClientApi } from "services/StreemViewConfigServices";

// Components
import { Button } from "components/shared/button/Button";
import FieldInput from "components/shared/field/field-input/FieldInput";

// Constants & Helpers
import { COLORS } from "constants/Common";
import { trimObject } from "helpers/ObjectHelper";

// Styles
import styles from "./CreateClient.module.scss";

const CreateClient = () => {
  const [loading, setLoading] = useState(false);

  const handleCreateClient = async (data) => {
    setLoading(true);
    try {
      const trimData = trimObject(data);
      await createClientApi(trimData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles["wrap"]}>
      <h3>Create Client</h3>
      <Formik
        initialValues={{
          clientName: "",
          description: "",
        }}
        onSubmit={(values) => handleCreateClient(values)}
      >
        {(form) => (
          <Form className={styles["form"]}>
            <FieldInput
              label="Client Name"
              name="clientName"
              placeHolder="Client Name"
              required
            />
            <FieldInput
              label="Description"
              name="description"
              placeHolder="Description"
              required
            />
            <div className={styles["footer"]}>
              {loading ? (
                <BeatLoader color={COLORS.black} loading={true} size={8} />
              ) : (
                <Button
                  name="Create Client"
                  className="btn-primary-fill"
                  handleClick={form.handleSubmit}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateClient;
