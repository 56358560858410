import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";

// Helpers
import { pickCountColor } from "helpers/ColorHelper";
import {
  convertStringEnter,
  getTypeNavigateToReview,
} from "helpers/CommonHelper";

//Constants
import { PATH_NAME } from "constants/Common";

// Components
import { HeatMapCell } from "./HeatMapCell";
import ProgressColorBar from "components/shared/progress-bar/ProgressColorBar";

// Stores
import { resetStoreReview, setParamSearchReview } from "store/GeneralReviewReducer";
import { clearDatePickerData, updateDateTime } from "store/DatePickerReducer";

// Styles
import styles from "./HeatMap.module.scss";

const findMaxChatCount = (array) => {
  let max = 0;
  array.forEach((item) => {
    const max_val = item.reduce((accumulator, element) =>
      accumulator > element ? accumulator : element
    );
    if (max_val > max) max = max_val;
  });
  return max;
};

export const HeatMap = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const HEATMAP_MATRIX = 20;

  // Get data from heatmap redux
  const {
    paramSearch: {
      DataSources,
      Types = [],
      Search,
      start,
      end,
      timeZone,
      includeBlankDate,
      typeFilter,
    },
    heatMapData: { data, listName },
    quickFilter,
  } = useSelector((state) => state.heatMap);

  const [maxCount, setMaxCount] = useState(0);
  const [heatMapData, setHeatMapData] = useState(data);
  const [heatMapNames, setHeatMapNames] = useState(listName || []);

  const handleFullParticipants = () => {
    let newHeatMapData = [];
    heatMapData.forEach((item) =>
      newHeatMapData.push([
        ...item,
        ...[...Array(HEATMAP_MATRIX - heatMapData.length)].fill(0),
      ])
    );
    while (newHeatMapData.length < HEATMAP_MATRIX)
      newHeatMapData.push([...[...Array(HEATMAP_MATRIX)].fill(0)]);
    setHeatMapData([...newHeatMapData]);
    setHeatMapNames([
      ...heatMapNames,
      ...[...Array(HEATMAP_MATRIX - heatMapNames.length)].fill("-"),
    ]);
  };

  const isExistEntity = (id) =>
    quickFilter.entities.findIndex((item) => item.id === id) !== -1;

  // Handle add people to param
  const getPeopleParam = (indexParent, indexChild) => {
    let entities = [];
    let participants = [];
    // Handle add people to param
    const addPeopleToParam = (id) => {
      if (isExistEntity(id)) entities = [...entities, id];
      else participants = [...participants, id];
    };
    addPeopleToParam(heatMapNames[indexParent].id);
    addPeopleToParam(heatMapNames[indexChild].id);
    return { entities, participants };
  };

  const navigateToReview = (indexParent, indexChild) => {
    // Clear review store
    dispatch(resetStoreReview());
    dispatch(clearDatePickerData());
    
    // Store param to review reducer
    const entities = getPeopleParam(indexParent, indexChild).entities;
    const participants = getPeopleParam(indexParent, indexChild).participants;
    dispatch(
      setParamSearchReview({
        dataSources: DataSources,
        searchInput: Search,
        searchMsgInput: Search,
        entities,
        participants,
        dataTypes: getTypeNavigateToReview(Types),
        start,
        end,
        timeZone,
        heatmapParticipants: [...entities, ...participants],
      })
    );
    // Store date time param to date time reducer
    dispatch(
      updateDateTime({
        dateTimeDataStart: start,
        dateTimeDataEnd: end,
        timeZone,
        typeFilter,
        includeBlankDate,
      })
    );
    // Navigate include param search of heatmap => keep search condition when back
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.streems}`, {
      state: { fromScreen: PATH_NAME.communicationAnalyzer },
    });
  };

  // Making identifiers tooltip title
  const makeIdentifierTitle = (array = []) => {
    let title = "";
    array.forEach((item, index) => {
      title += array.length - 1 === index ? `${item.value}` : `${item.value}, `;
    });
    return title ? ` (${title})` : "";
  };

  useEffect(() => {
    if (heatMapData) {
      if (heatMapData.length < HEATMAP_MATRIX) handleFullParticipants();
      setMaxCount(findMaxChatCount(heatMapData));
    }
  }, [heatMapData]);

  return (
    <div className={clsx(styles["heatmap-content"])}>
      <div className={styles["heatmap-content-articles"]}>
        <div className={styles["heatmap-matrix"]}>
          <table>
            <tbody>
              <tr className={styles["header-table"]}>
                <td></td>
                {heatMapNames.map((item, index) => (
                  <td className={styles["heatmap-col"]} key={index}>
                    <div
                      className={styles["heatmap-col__title"]}
                      title={`${item.name}${makeIdentifierTitle(
                        item.identifiers
                      )}`}
                    >
                      {convertStringEnter(item.name)}
                    </div>
                  </td>
                ))}
              </tr>
              {heatMapData.map((item, indexParent) => (
                <tr key={indexParent}>
                  <td
                    className={styles["heatmap-row-title"]}
                    title={`${
                      heatMapNames[indexParent]?.name
                    }${makeIdentifierTitle(
                      heatMapNames[indexParent]?.identifiers
                    )}`}
                  >
                    {convertStringEnter(heatMapNames[indexParent]?.name)}
                  </td>
                  {item.map((cell, indexChild) => {
                    const heatMapColor = pickCountColor(cell, maxCount);
                    return (
                      <HeatMapCell
                        key={indexChild}
                        heatMapColor={heatMapColor}
                        count={cell}
                        onClickCell={() =>
                          navigateToReview(indexParent, indexChild)
                        }
                        members={{
                          firstMember: heatMapNames[indexChild]?.name,
                          secondMember: heatMapNames[indexParent]?.name,
                        }}
                      />
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {maxCount > 0 && (
          <div className={styles["progress-bar-heatmap"]}>
            <ProgressColorBar maxCount={maxCount} />
          </div>
        )}
      </div>
    </div>
  );
};

HeatMap.propTypes = {
  loading: PropTypes.bool,
  isEmptyData: PropTypes.bool,
};
