//Intelligent Search Reducer

import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import { SORT_BY } from "constants/Constants";
import { PAGINATION } from "constants/Common";
import { COLUM_RESULT } from "constants/StreemConstant";

export const paramReviewInit = {
  searchInput: null,
  searchMsgInput: null,
  dataSources: [],
  dataTypes: [],
  entities: [],
  participants: [],
  heatmapParticipants: [],
  emojis: [],
  tags: [],
  paginationParams: {
    columnSort: COLUM_RESULT.dateLast.key,
    orderBy: SORT_BY.desc,
    pageNumber: PAGINATION.pageNumberDefault,
    pageSize: PAGINATION.recordPerPageTable,
  },
  start: null,
  end: null,
  includeBlankDate: false,
  isNewest: false,
  isShowRecents: false,
  isSecondSearch: false,
};

const initialState = {
  quickSelect: {
    dataSources: [],
    dataTypes: [],
    entities: [],
    participants: [],
    tags: [],
  },
  paramSearch: paramReviewInit,
  generalReviews: [],
  generalInfoPreview: null,
  idDoc: "",
  pageByDocId: false,
  paging: {
    totalRecords: 0,
    recordNumber: 0,
    pageIndex: 0,
  },
  resultMessageCount: 0,
  filteredTags: [],
  negateTagQuery: false,
  selectAllUntagged: false,
  searchError: '',
  searchHitError: ''
};

const generalReviewSlice = createSlice({
  name: "generalReview",
  initialState,
  reducers: {
    setQuickSelect: (state, action) => {
      state.quickSelect = {
        ...state.quickSelect,
        ...action.payload,
      };
    },
    setParamSearchReview: (state, action) => {
      state.paramSearch = isEmpty(action.payload)
        ? {}
        : {
            ...state.paramSearch,
            ...action.payload,
            searchMsgInput: action.payload.searchMsgInput,
          };
    },
    resetParamSearchReview: (state) => {
      state.paramSearch = { ...state.paramSearch, ...initialState.paramSearch };
    },
    setGeneralReviews: (state, action) => {
      state.generalReviews = action.payload;
    },
    setGeneralInfoPreview: (state, action) => {
      state.generalInfoPreview = action.payload;
    },
    resetGeneralInfoPreview: (state) => {
      state.generalInfoPreview = {};
    },
    setPaging: (state, action) => {
      state.paging = {
        ...state.paging,
        ...action.payload,
      };
    },
    setIdDoc: (state, action) => {
      state.idDoc = action.payload;
    },
    setPageByDocId: (state, action) => {
      state.pageByDocId = action.payload;
    },
    setParticipantsChat: (state, action) => {
      state.generalInfoPreview = {
        ...state.generalInfoPreview,
        participants: action.payload,
      };
    },
    resetStoreReview: () => {
      return initialState;
    },
    setResultMessageCount: (state, action) => {
      state.resultMessageCount = action.payload;
    },
    setFilteredTags: (state, action) => {
      state.filteredTags = action.payload;
    },
    setNegateTagQuery: (state, action) => {
      state.negateTagQuery = action.payload;
    },
    setAllUntagged: (state, action) => {
      state.selectAllUntagged = action.payload;
    },
    setSearchError: (state, action) => {
      state.searchError = action.payload;
    },
    setSearchHitError: (state, action) => {
      state.searchHitError = action.payload;
    }
  },
});
export const {
  setQuickSelect,
  setParamSearchReview,
  setGeneralReviews,
  setGeneralInfoPreview,
  setPaging,
  setIdDoc,
  setPageByDocId,
  setParticipantsChat,
  resetStoreReview,
  resetGeneralInfoPreview,
  resetParamSearchReview,
  setResultMessageCount,
  setFilteredTags,
  setNegateTagQuery,
  setAllUntagged,
  setSearchError,
  setSearchHitError
} = generalReviewSlice.actions;
export const GeneralReviewReducer = generalReviewSlice.reducer;
