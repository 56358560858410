import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './SimpleProgressBar.module.scss';

const SimpleProgressBar = ({ percentage, label, tooltip }) => {
  return (
    <div className={styles['progress-bar']} title={tooltip}>
      <div className={styles['progress-fill']} style={{ width: `${percentage}%` }}>
        <span>{label}</span>
      </div>
    </div>
  );
};

SimpleProgressBar.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  tooltip: PropTypes.string
};

export default SimpleProgressBar;
