import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SpreadsheetComponent, getRangeAddress } from '@syncfusion/ej2-react-spreadsheet';
import { getSpreadsheetJson } from '../../services/AttachmentService';
import SpinnerLoading from '../shared/spinner-loading/SpinnerLoading';

// Constants
import { ZERO_BYTE_ERROR, NOT_FOUND_ERROR, TOO_LARGE_ERROR, RENDER_ERROR, UNSUPPORTED_FILE_ERROR } from 'constants/NativeViewerConstants';

// Styles
import styles from './SpreadsheetAttachmentRenderer.module.scss';

const SpreadsheetAttachmentRenderer = (props) => {
    const {
        attachmentId,
        projectId,
    } = props;

    const viewerRef = useRef(null);
    const [viewerInstance, setViewerInstance] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [showCannotRender, setShowCannotRender] = useState(false);
    const [cannotRenderMessage, setCannotRenderMessage] = useState('');
    const [previewJson, setPreviewJson] = useState(null);

    const handleViewerLoaded = (instance) => {
        setViewerInstance(instance);
    };

    const openFailure = (args) => {
        if (args?.status?.length > 0) {
            setShowCannotRender(true);
            setCannotRenderMessage(RENDER_ERROR);
        }
    };

    const contextMenuBeforeOpen = (args) => {
        viewerInstance.removeContextMenuItems([
            // Cell context menu items
            'Cut',
            'Paste',
            'Paste Special',
            'Hyperlink',
            'Add Note',
            // Column context menu items
            'Insert Column',
            'Delete Column',
            'Filter',
            'Sort',
            // Sheet context menu items
            'Delete',
            'Insert',
            'Rename',
            'Duplicate',
            'Protect Sheet',
            'Unprotect Sheet',
            'Move Right',
            'Move Left',
            // Row context menu items
            'Insert Rows',
            'Insert Row',
            'Delete Rows',
            'Delete Row',
        ]);
    };

    useEffect(() => {
        const fetchPreview = async () => {
            const checkWorkbookForUnsupportedFile = (innerWorkbookJson) => {
                try {
                    const json = JSON.parse(JSON.parse(innerWorkbookJson));
                    if (json.Workbook === 'UnsupportedFile') {
                        return true;
                    }
                } catch (error) {
                    return false;
                }

                return false;
            };
            
            try {
                const response = await getSpreadsheetJson(projectId, attachmentId);

                if (checkWorkbookForUnsupportedFile(response.data)) {
                    setCannotRenderMessage(UNSUPPORTED_FILE_ERROR);
                    setShowCannotRender(true);
                    setIsLoading(false);
                    return;
                }

                setPreviewJson(response.data);
            } catch (error) {
                setShowCannotRender(true);
                if (error.response?.status === 400 && error.response?.data?.message?.includes('file is empty')) {
                    setCannotRenderMessage(ZERO_BYTE_ERROR);
                } else if (error.response?.status === 404) {
                    setCannotRenderMessage(NOT_FOUND_ERROR);
                } else if (error.response?.status === 413) {
                    setCannotRenderMessage(TOO_LARGE_ERROR);
                } else {
                    setCannotRenderMessage(RENDER_ERROR);
                }
                setIsLoading(false);
            }
        };

        fetchPreview();
    }, [projectId, attachmentId]);

    useEffect(() => {
        const setViewerSettings = () => {
            viewerInstance.activeSheetIndex = 0;
            viewerInstance.hideFileMenuItems(['File', 'New', 'Open', 'Save As'], true);
        };

        const lockSheets = () => {
            for (let i = 0; i < viewerInstance.sheets.length; i++) {
                const sheetName = viewerInstance.sheets[i].name;
                const settings = {
                    selectCells: true,
                    formatCells: true,
                    formatColumns: true,
                    formatRows: true
                }
                viewerInstance.protectSheet(sheetName, settings);

                const address = getRangeAddress([0, 0, viewerInstance.sheets[i].usedRange.rowIndex, viewerInstance.sheets[i].usedRange.colIndex]);
                viewerInstance.lockCells(`${sheetName}!${address}`, true);
            }
        }

        if (previewJson && viewerInstance) {
            try {
                viewerInstance.openFromJson({ file: previewJson });
                lockSheets();
                setViewerSettings();
            } catch (error) {
                setShowCannotRender(true);
                setCannotRenderMessage(RENDER_ERROR);
            }
            setIsLoading(false);
        }
    }, [previewJson, viewerInstance]);

    useEffect(() => {
        return () => {
            if (viewerInstance) {
                viewerInstance.destroy();
                setPreviewJson(null);
            }
        }
    }, []);

    return showCannotRender ? (
        <div className={styles['cannot-render']}>
            <img
                src="/images/image-notfound.png"
                alt="not found"
            />
            <p>{cannotRenderMessage}</p>
        </div>
    ) : (
        <>
            {isLoading &&
                <div className={styles['loading-spinner-container']}>
                    <SpinnerLoading isCenter={false} />
                </div>}
            <SpreadsheetComponent
                id="spreadsheet-viewer"
                ref={instance => {
                    viewerRef.current = instance;
                    if (instance) {
                        handleViewerLoaded(instance);
                    }
                }}
                openFailure={openFailure}
                contextMenuBeforeOpen={contextMenuBeforeOpen}
                allowSave={false}
                allowDelete={false}
                allowInsert={false}
                allowFindAndReplace={false}
                allowMerge={false}
                allowAutoFill={false}
                showRibbon={false}
                showFormulaBar={false}
                scrollSettings={{ isFinite: true }}
                cssClass={isLoading
                    ? styles['spreadsheet-renderer-hidden']
                    : styles['spreadsheet-renderer']}
            />
        </>
    )
};

SpreadsheetAttachmentRenderer.propTypes = {
    attachmentId: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(SpreadsheetAttachmentRenderer);
