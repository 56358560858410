import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

// Helpers
import { getSourceIconByName } from "helpers/CommonHelper";

// Styles
import styles from "./ViewAppDetail.module.scss";

const ViewAppDetail = ({ appList = [] }) => {
  const moreIconRef = useRef(null);
  const listRef = useRef(null);
  const [showDetail, setShowDetail] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  useOnClickOutside(moreIconRef, () => setShowDetail(false), listRef.current);

  useEffect(() => {
    if (showDetail && moreIconRef.current) {
      const rect = moreIconRef.current.getBoundingClientRect();
      const popupWidth = 540;
      const windowHeight = window.innerHeight;
      const bottomMargin = 24;

      const leftPosition = rect.right - popupWidth;

      let topPosition = rect.bottom + 16;
      const popupHeight = listRef.current?.offsetHeight || 340;
      
      if (topPosition + popupHeight + bottomMargin > windowHeight) {
        topPosition = windowHeight - popupHeight - bottomMargin;
      }

      setPopupPosition({
        top: topPosition,
        left: leftPosition,
      });
    }
  }, [showDetail]);

  return (
    <div className={styles["view-detail"]}>
      <img
        className={styles["more-icon"]}
        src="/images/see-more.svg"
        alt="More"
        onClick={() => setShowDetail(!showDetail)}
        ref={moreIconRef}
      />
      {showDetail && (
        <ul 
          className={styles["app-list"]} 
          ref={listRef}
          style={{
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
          }}
        >
          {appList.map((item) => (
            <li className={styles["app-list-item"]} key={item}>
              <img
                src={getSourceIconByName(item, "other")}
                alt="source-icon"
                className={styles["source-icon"]}
              />
              <span className={styles["app-name"]} title={item}>
                {item}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ViewAppDetail.propTypes = {
  appList: PropTypes.arrayOf(PropTypes.string),
};

export default ViewAppDetail;
