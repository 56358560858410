import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import ItemResultInline from "components/shared/item-result-inline/ItemResultInline";
import ViewAppDetail from "components/data-source/view-app-detail/ViewAppDetail";

// Styles
import styles from "./DataSourceCardItem.module.scss";

const DataSourceCardItem = ({ header, items, installedApps }) => {
  return (
    <div className={styles["card-item"]}>
      <div className={styles["card-item-header"]}>
        <h4>{header}</h4>
      </div>
      <div className={styles["card-item-body"]}>
        {items.map((item) => (
          item.label === "Installed Apps" ? (
            <div className={styles["info-item"]} key={item.label}>
              <div className={styles["info-label"]}>
                {item.label + ":"}
              </div>
              <span className={styles["info-value"]}>
                <div className={styles["view-detail-app"]}>
                  {installedApps?.length !== 0 && (
                    <ViewAppDetail appList={installedApps} />
                  )}
                </div>
                <strong>{item.value}</strong>
              </span>
            </div>
          ) : (
            <Fragment key={item.label}>
              <ItemResultInline
                label={item.label}
                value={item.value}
                fwBold={item.fwBold}
              />
              {item?.children?.length > 0 &&
                item.children.map((child) => (
                  <div className={styles["info-item-child"]} key={child.label}>
                    <ItemResultInline
                      label={child.label}
                      value={child.value}
                    />
                  </div>
                ))}
            </Fragment>
          )
        ))}
      </div>
    </div>
  );
};

DataSourceCardItem.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      fwBold: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
      ),
    })
  ).isRequired,
  installedApps: PropTypes.arrayOf(PropTypes.string),
};

export default DataSourceCardItem;
