const DATE_SELECTOR = {
  type: {
    unKnow: -1,
    before: 0,
    after: 1,
    between: 2,
  },
  timeFrame: {
    am: "AM",
    pm: "PM",
    start: "start",
    end: "end",
  },
};

const TIME_ZONES = [
  { name: "UTC (Coordinated Universal Time)", value: "Etc/UTC" },
  { name: "UTC-12:00 (Baker Island Time)", value: "Etc/GMT+12" },
  { name: "UTC-11:00 (Samoa Standard Time)", value: "Pacific/Pago_Pago" },
  { name: "UTC-10:00 (Hawaii-Aleutian Standard Time)", value: "Pacific/Honolulu" },
  { name: "UTC-9:00 (Alaska Standard Time)", value: "America/Anchorage" },
  { name: "UTC-8:00 (Pacific Standard Time)", value: "America/Los_Angeles" },
  { name: "UTC-7:00 (Mountain Standard Time)", value: "America/Denver" },
  { name: "UTC-6:00 (Central Standard Time)", value: "America/Chicago" },
  { name: "UTC-5:00 (Eastern Standard Time)", value: "America/New_York" },
  { name: "UTC-4:00 (Atlantic Standard Time)", value: "America/Halifax" },
  { name: "UTC-3:00 (Brazil Time)", value: "America/Sao_Paulo" },
  { name: "UTC-2:00 (Fernando de Noronha Time)", value: "America/Noronha" },
  { name: "UTC-1:00 (Azores Standard Time)", value: "Atlantic/Azores" },
  { name: "UTC+1:00 (Central European Time)", value: "Europe/Berlin" },
  { name: "UTC+2:00 (Eastern European Time)", value: "Europe/Athens" },
  { name: "UTC+3:00 (Arabian Standard Time)", value: "Asia/Riyadh" },
  { name: "UTC+4:00 (Gulf Standard Time)", value: "Asia/Dubai" },
  { name: "UTC+5:00 (Pakistan Standard Time)", value: "Asia/Karachi" },
  { name: "UTC+6:00 (Bangladesh Standard Time)", value: "Asia/Dhaka" },
  { name: "UTC+7:00 (Indochina Time)", value: "Asia/Bangkok" },
  { name: "UTC+8:00 (China Standard Time)", value: "Asia/Shanghai" },
  { name: "UTC+9:00 (Japan Standard Time)", value: "Asia/Tokyo" },
  { name: "UTC+10:00 (Australian Eastern Standard Time)", value: "Australia/Sydney" },
  { name: "UTC+11:00 (Solomon Islands Time)", value: "Pacific/Guadalcanal" },
  { name: "UTC+12:00 (New Zealand Standard Time)", value: "Pacific/Auckland" },
  { name: "UTC+13:00 (Tonga Time)", value: "Pacific/Tongatapu" },
  { name: "UTC+14:00 (Line Islands Time)", value: "Pacific/Kiritimati" },
];

export { DATE_SELECTOR, TIME_ZONES };
