const COLUMN_NAME_SOURCE = {
  deviceName: {
    value: "dataSourceDescription",
    label: "Device Name",
    isSortable: true,
  },
  custodian: {
    value: "custodianName",
    label: "Custodian",
    isSortable: true,
  },
  createdDate: {
    value: "createdDate",
    label: "Created Date/Time",
    isSortable: true,
  },
  importedDate: {
    value: "importedDate",
    label: "Imported Date/Time",
    isSortable: true,
  },
  status: {
    value: "importProcess",
    label: "Data Status",
    isSortable: true,
  },
  nativeStatus: {
    value: "nativeStatus",
    label: "Native Status",
    isSortable: true,
  },
  progress: {
    value: "uploadProgress",
    label: "Progress",
    isSortable: false,
  },
  importedRecordCount: {
    value: "streemsCount",
    label: "Imported Records",
    isSortable: true,
  },
  importedSized: {
    value: "blobBytes",
    label: "Imported Size",
    isSortable: true,
  },
  action: {
    value: "action",
    label: "",
    isSortable: false
  },
};

const JOB_CATEGORY = {
  draft: { label: "Draft", value: 1 },
  basic: { label: "Basic", value: 2 },
  full: { label: "Full", value: 3 },
};

const IMPORT_PROCESS = {
  ready: {
    label: "Ready",
    value: 1,
    borderColor: "#29C3BE",
    backgroundColor: "#DFF6F5",
  },
  inprogress: {
    label: "In Progress",
    value: 2,
    borderColor: "#1580EF",
    backgroundColor: "#DCECFD",
  },
  failed: {
    label: "Failed",
    value: 3,
    borderColor: "#FD6561",
    backgroundColor: "#FDEAE9",
  },
  completed: {
    label: "Completed",
    value: 4,
    borderColor: "#62B58F",
    backgroundColor: "#E7F4EE",
  },
  canceling: {
    label: "Canceling",
    value: 5,
    borderColor: "#FFC533",
    backgroundColor: "rgba(255, 197, 51, 0.15)",
  },
  notReady: {
    label: "Not Ready",
    value: 6,
    borderColor: "#D3D3D3",
    backgroundColor: "#F1F1F1",
  },
  uploading: {
    label: "Uploading",
    value: 7,
    borderColor: "#1580EF",
    backgroundColor: "#DCECFD",
  },
  pending: {
    label: "Pending",
    value: 8,
    borderColor: "#FFC533",
    backgroundColor: "#FFF4E5",
  },
  deleting: {
    label: "Deleting",
    value: 9,
    borderColor: "#FFC533",
    backgroundColor: "#FFF4E5",
  },
  deleteFailed: {
    label: "Failed",
    value: 10,
    borderColor: "#FD6561",
    backgroundColor: "#FDEAE9",
  },
};



const STAGE_LOADFILE = { key: 0, label: "Reading Loadfile" };
const STAGE_IMPORTING_DATA = { key: 1, label: "Importing Data" };
const STAGE_POST_IMPORT_SCRIPTS = { key: 2, label: "Post Import Scripts" };
const STAGE_INDEXING = { key: 3, label: "Indexing" };

const STAGE_GENERATING_THUMBNAILS = { key: 4, label: "Generating Thumbnails" };
const STAGE_CALCULATING_DATA_SOURCE_SIZE = { key: 5, label: "Calculating Data Source Size"};
const STAGE_EXTRACTING_ATTACHMENTS = { key: 6, label: "Extracting Attachments" };
const STAGE_DONE = { key: 100, label: "Done" };

const DATA_IMPORT_STAGES = [
  STAGE_LOADFILE,
  STAGE_IMPORTING_DATA,
  STAGE_POST_IMPORT_SCRIPTS,
  STAGE_INDEXING,
  STAGE_DONE,
];

const NATIVE_IMPORT_STAGES = [
  STAGE_GENERATING_THUMBNAILS,
  STAGE_CALCULATING_DATA_SOURCE_SIZE,
  STAGE_EXTRACTING_ATTACHMENTS,
  STAGE_DONE,
];

const IMPORT_TYPE = {
  datTeams: {
    value: 1,
    label: "DAT (Teams - MSG)",
    typeExtension: "dat",
  },
  datAutoStreem: {
    value: 2,
    label: "DAT (AutoStreem)",
    typeExtension: "dat",
  },
  xmlCellebrite: {
    value: 3,
    label: "XML (Cellebrite)",
    typeExtension: "xml",
  },
  datSlackChat: {
    value: 4,
    label: "DAT (Slack - Chat)",
    typeExtension: "dat",
  },
};

const POPUP_TYPE = {
  delete: 1,
  cancelData: 2,
  cancelNative: 3,
  cancelUpload: 4,
};

const NOTIF_MSG_TYPE = {
  loadfile: 1,
  natives: 2,
  delete: 3,
}

export {
  COLUMN_NAME_SOURCE,
  JOB_CATEGORY,
  IMPORT_PROCESS,
  IMPORT_TYPE,
  POPUP_TYPE,
  NOTIF_MSG_TYPE,
  DATA_IMPORT_STAGES,
  NATIVE_IMPORT_STAGES,
  STAGE_LOADFILE,
  STAGE_IMPORTING_DATA,
  STAGE_POST_IMPORT_SCRIPTS,
  STAGE_INDEXING,
  STAGE_GENERATING_THUMBNAILS,
  STAGE_CALCULATING_DATA_SOURCE_SIZE,
  STAGE_EXTRACTING_ATTACHMENTS,
  STAGE_DONE,
};
